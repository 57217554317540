import React, { useState, useEffect } from "react";
import {
    Box,
    Button,
    Grid,
    FormControl,
    InputLabel,   
    TextField,
    FormHelperText,
    Autocomplete
} from "@mui/material";
import { useAuth } from '../hooks/useAuth';
import PutPostPatchFetch from "../hooks/PutPostPatchFetch";
import RoleDropdown from "./AddUserComponents/RoleDropdown";
import { LocalizationProvider, DateField } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ROUTE_ADD_USER, ROUTE_USERS } from "../routes/Routes";
import { useNavigate } from "react-router-dom";
import ClosingAlert from "../components/ClosingAlert";
import RegionDropdown from "./AddUserComponents/RegionDropdown";
import UserStatusDropdown from "./AddUserComponents/UserStatusDropdown";
import { BaseButton } from "../components/BaseButton/BaseButton";
const inputLabelStyles = {
    margin: "1rem 0 0 1rem",
};

const textFieldStylesOne = {
    m: 1,
    width: '25rem',
    margin: '1.5rem 1rem'
};

const textFieldStylesTwo = {
    m: 1,
    width: '11.5rem',
    margin: '1.5rem 1rem'
};

const selectStylesThree = {
    m: 1,
    width: '10rem',
    margin: '1rem'
};

const AddNewUser = () => {
    const [userAccountName, setUserAccountName] = useState("");
    const [emailAddress, setEmailAddress] = useState("");
    const [contractorCompany, setContractorCompany] = useState("");
    const [jobTitle, setJobTitle] = useState("");
    const [employeeType, setEmployeeType] = useState("");
    const [contractStartDate, setContractStartDate] = useState("");
    const [contractEndDate, setContractEndDate] = useState("");
    const [separationDate, setSeparationDate] = useState("");
    const [userStatusid, setUserStatusId] = useState(0);
    const [userRoleId, setuserRoleId] = useState();
    const [tieIsOverride]= useState(false);
    const [regions, setRegions] = useState([]);
    const [successful_data_entry, setUserDataSaved] = useState(false);
    const [failed_data_entry, setUserDataFailed] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const { user } = useAuth();
    const [createdBy] = useState(user.email);
    const navigate = useNavigate();

    useEffect(() => {
        document.title = ROUTE_ADD_USER.title;
    }, []);

    const SubmitData = async () => {
        const userObject = {
            "UserAccountName": userAccountName,
            "EmailAddress": emailAddress,
            "ContractorCompany": contractorCompany,
            "JobTitle": jobTitle,
            "EmployeeType": employeeType,
            "ContractStartDate": contractStartDate,
            "ContractEndDate": contractEndDate,
            "SeparationDate": separationDate,
            "CreatedDate": "",
            "RegionNumber": regions[0],
            "Regions": regions,
            "CreatedBy": createdBy,
            "UserStatusId": userStatusid,
            "UserRoleId": userRoleId,
            "TieIsOverride": tieIsOverride
        };
        const route = '/v1/SystemUser/AddUser';
        const save = await PutPostPatchFetch(route, 'POST', userObject);
        const { Success, Message, Errors } = save;

        if (Success === true) {
            setUserDataSaved(true);
            setUserDataFailed(false);
        } else {
            setUserDataSaved(false);
            setUserDataFailed(true);
            setErrorMessage(Errors? Errors[0].Message : save.title);
            console.error(Errors ? Errors[0].Message : save.title)
        }

        if (Error) {
            console.error(Error)
        }
    }

    const validateValues = () => {
        if (!userAccountName || !validateEmail(userAccountName)) return false;
        if (!regions) return false;
        if (regions.length < 1)  return false;
        if (!userRoleId)  return false;
        if (userStatusid == 0)  return false;
        if(!employeeType) return false;

        if (employeeType !== "Federal") {
            if (!contractStartDate) return false;
            if (!contractEndDate) return false;
            if (JSON.stringify(contractStartDate) === null || JSON.stringify(contractStartDate) === 'null') return false;
            if (JSON.stringify(contractEndDate) === null || JSON.stringify(contractEndDate) === 'null') return false;
        }
        if (!separationDate) return false;
        if (JSON.stringify(separationDate) === null || JSON.stringify(separationDate) === 'null') return false;

        return true;
    };


    const handleEmailAndUserAccount = (e) => {
        setEmailAddress(e.target.value);
        setUserAccountName(e.target.value);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            SubmitData();
        }
    };

    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    return (
        <>
            <h1 style={{ color: "#06152B" }}>Add A New User</h1>
            <BaseButton
                buttonType={"normal"}
                label={"Go Back"}
                onClick={() => {
                    navigate(ROUTE_USERS.withSlash);
                }}
            />
            <Box
                component="form"
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap'
                }}
                noValidate
                autoComplete="off"
            >
                <Grid container spacing={2}>
                    <FormControl sx={{ mt: 1, mb: 1 }} error={!employeeType}>
                        <InputLabel
                            id="employeeTypeLabel"
                            style={inputLabelStyles}
                            htmlFor="employee-type"
                        >
                        </InputLabel>
                        <Autocomplete
                            value={employeeType ? { label: employeeType, value: employeeType } : null}
                            options={[
                                { label: "Contractor", value: "Contractor" },
                                { label: "Federal", value: "Federal" }
                            ]}
                            getOptionLabel={(option) => option.label}
                            style={selectStylesThree}
                            onChange={(e, newValue) => {
                                setEmployeeType(newValue?.value || ''); // Set the value to the selected option's value
                                if (newValue?.value === "Federal") {
                                    setContractStartDate(null);
                                    setContractEndDate(null);
                                }
                            }}                            
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Employee Type *"
                                    variant="outlined"
                                    onKeyDown={handleKeyDown}
                                    error={!employeeType}
                                    InputProps={{
                                        ...params.InputProps,
                                        inputProps: {
                                            ...params.inputProps,
                                            'aria-required': 'true',
                                        },
                                    }}
                                />
                            )}
                        />
                        {!employeeType && <FormHelperText>Required *</FormHelperText>}
                    </FormControl>
                    <TextField
                        variant="outlined"
                        label="Email Address (User Account)"
                        type="email"
                        value={emailAddress}
                        onChange={handleEmailAndUserAccount}
                        inputProps={{
                            onKeyDown: handleKeyDown,
                            "aria-required": "true"
                        }}
                        InputLabelProps={{
                            style: { color: 'black' }
                        }}
                        error={!validateEmail(emailAddress)}
                        helperText={validateEmail(emailAddress) ? "" : "Enter a valid email *"}
                        style={textFieldStylesOne}
                        required={true}
                    />
                    <TextField
                        variant="outlined"
                        label="Contractor Company"
                        type="string"
                        value={contractorCompany}
                        onChange={(e) => setContractorCompany(e.target.value)}
                        inputProps={{
                            onKeyDown: handleKeyDown
                        }}
                        InputLabelProps={{
                            style: { color: 'black' }
                        }}
                        style={textFieldStylesOne}
                    />
                    <TextField
                        variant="outlined"
                        label="Job Title"
                        type="string"
                        value={jobTitle}
                        onChange={(e) => setJobTitle(e.target.value)}
                        inputProps={{
                            onKeyDown: handleKeyDown
                        }}
                        InputLabelProps={{
                            style: { color: 'black' }
                        }}
                        style={textFieldStylesOne}
                    />
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <FormControl>
                            <DateField
                                variant="outlined"
                                id="contract-start-date"
                                label="Contract Start Date *"
                                format="MM-DD-YYYY"
                                value={contractStartDate}
                                onChange={(newValue) => setContractStartDate(newValue)}
                                inputProps={{
                                    onKeyDown: handleKeyDown,
                                    "aria-required": employeeType !== "Federal",
                                }}
                                error = {!contractStartDate && employeeType !== "Federal"}
                              
                                InputLabelProps={{
                                    style: { color: 'black' }
                                }}
                                style={textFieldStylesTwo}
                                helperText={(!contractStartDate && employeeType !== "Federal") ? "Required *" : null}
                            />
                        </FormControl>

                        <FormControl>
                            <DateField
                                variant="outlined"
                                id="contract-end-date"
                                label="Contract End Date *"
                                format="MM-DD-YYYY"
                                value={contractEndDate}
                                onChange={(newValue) => setContractEndDate(newValue)}
                                inputProps={{
                                    onKeyDown: handleKeyDown,
                                    "aria-required": employeeType !== "Federal",
                                }}
                                error = {!contractEndDate && employeeType !== "Federal"}
                                InputLabelProps={{
                                    style: { color: 'black' }
                                }}
                                style={textFieldStylesTwo}
                                helperText={(!contractEndDate && employeeType !== "Federal") ? "Required *" : null}
                            />
                        </FormControl>
                        <FormControl >
                            <DateField
                                variant="outlined"
                                id="contract-separation-date"
                                label="Separation Date *"
                                format="MM-DD-YYYY"
                                value={separationDate}
                                onChange={(newValue) => setSeparationDate(newValue)}
                                error={!separationDate}
                                inputProps={{
                                    onKeyDown: handleKeyDown,
                                    "aria-required": "true"
                                }}
                                InputLabelProps={{
                                    style: { color: 'black' }
                                }}
                                style={textFieldStylesTwo}
                                helperText={JSON.stringify(separationDate) === 'null' ? "Required" : ""}
                            />
                            {
                                !separationDate &&
                                <FormHelperText>Required *</FormHelperText>
                            }
                        </FormControl>
                    </LocalizationProvider>
                    <RegionDropdown value={regions} setter={setRegions} />
                    <RoleDropdown value={userRoleId} setter={setuserRoleId} />
                    <UserStatusDropdown value={userStatusid} setter={setUserStatusId} />
                </Grid>
            </Box>
            <div style={{ margin: '4rem 0' }}>
                <Button
                    variant="contained"
                    onClick={() => { SubmitData() }}
                    disabled={!validateValues()|| successful_data_entry}
                >
                    Submit
                </Button>
            </div>
            <ClosingAlert
                severity="success"
                message="User was saved successfully"
                visible={successful_data_entry}
                hideAlert={() => { setUserDataSaved(false) }}
            />
            <ClosingAlert
                severity="warning"
                message={errorMessage? errorMessage : "error.Please check validation of the inputs."}
                visible={failed_data_entry}
                hideAlert={() => { setUserDataFailed(false) }}
            />
            
        </>
    );
};

export default AddNewUser;