import { useEffect, useState, useCallback, useRef } from 'react';
import classNames from 'classnames';
import Form, { Item as FormItem, GroupItem, ColCountByScreen, RequiredRule as Required, RangeRule as Range, StringLengthRule, CustomRule } from 'devextreme-react/form';
import { FormTextBox } from '../FormTextBox/FormTextBox';
import { FormDateBox } from '../FormDateBox/FormDateBox';
import DataGrid, { Column, Form as DataGridForm, Lookup, Toolbar, Item, Editing, Popup, RequiredRule, RangeRule, CompareRule } from 'devextreme-react/data-grid';
import { ContractPersonnel } from './ContractPersonnel';
import Button from 'devextreme-react/button';
import { Contract, TaskOrder } from '../../types/contract';
import GetFetch from '../../hooks/GetFetch';
import notify from 'devextreme/ui/notify';

const URL_productCodes = '/v1/ProductCode/GetProductCodes';
const URL_productDescriptions = '/v1/ProductCode/GetProductCodeById/1';
const URL_taskOrderTypes = '/v1/TaskOrderType';
const URL_regions = '/v1/Region';
import { Endpoints } from '../../Enums/endpoints';
import { dateEditorOptions } from '../../Enums/datagridvalidations';

import './ContractForm.scss';

const {
    GET_PRODUCT_CODE_BY_ID,
    GET_PRODUCT_CODES,
    GET_TASKORDER_TYPE
} = Endpoints;

let pdList = [];

/** @typedef {import('../../types/contract').Contract} Contract */
/** @typedef {import('../../types/contract').TaskOrder} TaskOrder */

/**
 * @param {Contract} data
 * @param {boolean} editing
 * @param {function(field, value): void} updateField
 */

export const ContractFormDetails = ({ data, editing, updateField, updateObject }) => {

    const [regions, setRegions] = useState([]);
    const [taskOrderTypes, setTaskOrderTypes] = useState([]);
    const [currentTaskOrderType, setCurrentTaskOrderType] = useState(null);
    const [modTaskOrders, setModTaskOrders] = useState([]);
    const [displayTaskOrderTypes, setDisplayTaskOrderTypes] = useState([]);
    const [productCodes, setProductCodes] = useState([]);
    const [productDescriptions, setProductDescriptions] = useState([]);
    const [isModification, setIsModification] = useState(false);
    const [editLabel, setEditLabel] = useState();
    const [formDataDefaults, setFormDataDefaults] = useState(data);
    const [popupVisible, setPopupVisible] = useState(false);
    const [selectedRowIndex, setSelectedRowIndex] = useState(-1);
    const gridRef = useRef(null);

    useEffect(() => {
        fetchAndSet(URL_regions, setRegions);
        fetchAndSet(URL_taskOrderTypes, setTaskOrderTypes);
        fetchAndSet(URL_productCodes, setProductCodes);
        fetchAndSet(URL_productDescriptions, setProductDescriptions);
        const modTOs = data.TaskOrders.map(taskOrder => {
            if (taskOrder.TaskOrderTypeId === 1 || taskOrder.TaskOrderTypeId === 2) {
                return taskOrder;
            }
        });
        setModTaskOrders(modTOs);
        setFormDataDefaults(data);
    }, []);
    const fetchAndSet = async (URL, setter) => {
        const { Errors, Message, Success } = await GetFetch(URL);
        if (Success === true) {
            // URL === `${GET_PRODUCT_CODE_BY_ID}1` ?
            URL == URL_productDescriptions ? setter(Message.ProductDescriptions) : setter(Message);

            if (URL === URL_taskOrderTypes)
                setDisplayTaskOrderTypes(Message);
            console.log(Message);
        }
        else {
            console.error(Errors[0].Message);
        }
    }

    const renderTaskOrderForm = (e) => {
        const editItem = "Edit Task Order: " + e.row.data.Number;
        //return <a href='#' target='_self' aria-label={editItem} onClick={e => onEditTaskOrder(e)}>Edit</a>;
        return <Button onClick={onEditTaskOrder(e.row.data)}>Edit</Button>
    }

    const taskOrderTypeChanged = useCallback((e) => {
        const isMod = e.selectedItem.TaskOrderTypeId === 3;
        if (isMod)
            setModTaskOrders([]);
        //setIsModification(isMod);
    }, [isModification]);

    const onEditorPreparing = (e) => {
        if (e.parentType === 'dataRow' && e.dataField === 'ModifyTaskOrderId') {
            const isNotModification = e.row.data.TaskOrderTypeId !== 3;
            e.editorOptions.disabled = isNotModification;
            // const isNotModification = e.row.data.TaskOrderTypeId !== 3;
            //   e.editorOptions.dataSource = null;
            // if(isNotModification)
            //   setModTaskOrders([]);
        }
        // find the Lookup column
        const hasBaseTaskOrder = data.TaskOrders.some((x) => x.TaskOrderTypeId = 1);
        if (e.parentType === "dataRow" && e.dataField === "TaskOrderTypeId") {
            //e.editorOptions.onSelectionChanged = taskOrderTypeChanged;
            // change the dataSource option. You can pass a different array, or modify the existing lookup items
            e.editorOptions.dataSource = {
                store: {
                    type: "array",
                    data: taskOrderTypes
                },
                // specify postProcess
                postProcess: function (data) {
                    // modify items. Here, base is disabled if 1 already exists
                    let newData = data.map((x) => {
                        if (x.TaskOrderTypeId === 1) {
                            x.disabled = hasBaseTaskOrder;
                        }
                        return x;
                    });
                    return newData;
                }
            }
        }
    };


    function setTaskOrderType(rowData, value) {
        rowData.ModifyTaskOrderId = null;
        // if(value === 3)
        //   test1.current = [...test1.current, ...modTaskOrders];
        // else
        //   test1.current = [];
        this.defaultSetCellValue(rowData, value);
    }

    function setCellValue(editingColumn, rowData, value, currentRowData) {
        // rowData.ModifyTaskOrderId = null;
        // if(value === 3)
        //   test1.current = [...test1.current, ...modTaskOrders];
        // else
        //   test1.current = [];
        this.defaultSetCellValue(editingColumn, rowData, value, currentRowData);
        //this.defaultSetCellValue(rowData, value, currentRowData);
    };

    function processModTOs(itemData) {
        let result = [];
        console.log(formDataDefaults.TaskOrderTypeId);

        if (formDataDefaults.TaskOrderTypeId == 3)
            result = [...result, itemData]

        return result;
    };

    const customizeItem = useCallback((formItem) => {
        if (formItem && formItem.caption === 'Modification Number') {
            const dataGridInstance = gridRef?.current.instance
            const editing = dataGridInstance.option('editing');
            const rowIndex = dataGridInstance.getRowIndexByKey(editing?.editRowKey);
            const isMod = dataGridInstance.cellValue(rowIndex, 'TaskOrderTypeId') === 3;
            formItem.disabled = !isMod;
        }
    }, []);

    const getModTaskOrders = (options) => (
        {
            store: test1.current,
            postProcess: processModTOs
            // filter: options.data?.TaskOrderTypeId === 3 ? ["Number", "<>", 0] : null
            //  filter: (itemData) => {
            //   return options.data?.TaskOrderTypeId === 3;
            //  }
            // filter: options.data?.TaskOrderTypeId === 3 ? ["Number", "<>", 0] : ["Number", ">", "0"]
            // filter: options.data ? ["Number", "<>", 0] : null
            // options.data ? (options.data.TaskOrderTypeId === 3 ? modTaskOrders :[]) : []
            // disabled: (options.data ? (options.data.TaskOrderTypeId === 3 ? true :false) : false)
            // filter: options.data ? ['StateID', '=', options.data.StateID] : null,
        });

    const onTaskOrderInserting = (e) => {
        const hasBaseTaskOrder = data.TaskOrders.some((x) => x.TaskOrderTypeId = 1);
        // e.component.columnOption("ModifyTaskOrderId", "allowEditing", false)
        if (hasBaseTaskOrder && (e.data.taskOrderId === null || e.data.taskOrderId === undefined || e.data.taskOrderId === 0)) {
            //const updatedTaskOrderTypes = taskOrderTypes.slice(1);
            let newData = taskOrderTypes.map((x) => {
                if (x.TaskOrderTypeId === 1) {
                    x.disabled = hasBaseTaskOrder;
                }
                return x;
            });
            setDisplayTaskOrderTypes(newData);
        }
    };

    const onTaskOrderInserted = () => {
        setDisplayTaskOrderTypes(taskOrderTypes);
    };

    const taskOrderTypeOptions = { disabled: isModification };
    const awardDateContractCompare = useCallback((e) => {
        var newDate = new Date(new Date(e.value).toUTCString());
        var compareDate = (formDataDefaults.AwardDate !== null && formDataDefaults.AwardDate !== undefined && formDataDefaults.AwardDate !== '')
            ? new Date(formDataDefaults.AwardDate) : new Date(formDataDefaults.StartDate);
        return newDate >= compareDate;
    }, [formDataDefaults.AwardDate, formDataDefaults.StartDate]);

    const startDateTaskOrderCompare = useCallback((e) => {
        var newDate = new Date(new Date(e.value).toUTCString());
        var compareDate = (e.data?.AwardDate !== null && e.data?.AwardDate !== undefined && e.data?.AwardDate !== '')
            ? new Date(e.data?.AwardDate) : new Date(formDataDefaults.StartDate);
        return newDate >= compareDate;
    }, [formDataDefaults.StartDate]);

    const endDateTaskOrderCompare = useCallback((e) => {
        var newDate = new Date(new Date(e.value).toUTCString());
        var compareDate = new Date(e.data?.StartDate);
        return newDate >= compareDate;
    }, [formDataDefaults.EndDate]);

    const onEditTaskOrder = useCallback((e) => {
        setPopupVisible(true);
    }, []);

    const editRow = useCallback((e) => {
        const intRow = parseInt(e.element.id);
        setSelectedRowIndex(intRow);
        setPopupVisible(true);
        gridRef.current.instance.editRow(intRow);
    }, [gridRef, selectedRowIndex]);

    const buttonRender = useCallback((e) => {
        const taskOrderId = e.data.TaskOrderId;
        const taskOrderNumber = e.data.Number;
        setSelectedRowIndex(e.row.rowIndex);
        return <Button id={e.rowIndex} stylingMode='text' height={20} elementAttr={{ class: 'button-link' }}
            onClick={e => editRow(e)}>{'Edit: ' + taskOrderNumber}</Button>
    }, []);

    const onAddTaskOrder = useCallback(() => {
        setPopupVisible(true);
    }, []);

    const changePopupVisibility = useCallback((isVisble) => {
        setPopupVisible(isVisble);
    }, []);

    const onDataChanged = (field, newValue) => {
        setFormDataDefaults({ ...formDataDefaults, ...{ [field]: newValue } });
        updateField(field, newValue);
    };

    const onEditVisible = useCallback((e) => {
        const editItem = { "aria-label": "Edit Task Order: " + e.row.data.Number };
        setEditLabel(editItem);
    });

    const onSaveClick = useCallback(() => {
        notify({
            message: `New contact "${newContactData.firstName} ${newContactData.lastName}" saved`,
            position: { at: 'bottom center', my: 'bottom center' }
        },
            'success'
        );
        setFormDataDefaults({ ...formDataDefaults });
        setPopupVisible(false);
    }, []);

    const onCloneIconClick = useCallback((e) => {
        const clonedItem = { ...e.row.data, ID: getMaxID() };
    });

    return (
        <Form className={classNames({ 'plain-styled-form': true, 'view-mode': !editing })}
            labelMode='floating' customizeItem={customizeItem}>
            <GroupItem colCount={4} caption='General Information' >
                <ColCountByScreen xs={4} />
                <FormItem>
                    <FormTextBox
                        label='Contract Number'
                        value={formDataDefaults.Number}
                        isEditing={editing}
                        onValueChange={e => onDataChanged('Number', e)}>
                        <Required message='Contract Number is Required.' />
                        <StringLengthRule message='Contract Number must include 17 characters minimum.' min={17} />
                    </FormTextBox>
                </FormItem>
                <FormItem cssClass='accent'>
                    <FormDateBox
                        name='Award Date'
                        label='Award Date'
                        value={formDataDefaults.AwardDate}
                        isEditing={!editing}
                        onValueChange={e => onDataChanged('AwardDate', e)} />
                </FormItem>
                <FormItem>
                    <FormDateBox
                        name='Start Date'
                        label='Start Date'
                        value={formDataDefaults.StartDate}
                        isEditing={!editing}
                        onValueChange={e => onDataChanged('StartDate', e)}
                        minValue={formDataDefaults.AwardDate ? formDataDefaults.AwardDate : undefined}
                        minName='Award Date'
                    >
                    </FormDateBox>
                </FormItem>
                <FormItem cssClass='accent'>
                    <FormDateBox
                        name='End Date'
                        label='End Date'
                        value={formDataDefaults.EndDate}
                        isEditing={!editing}
                        onValueChange={e => onDataChanged('EndDate', e)}
                        minValue={formDataDefaults.StartDate}
                        minName='Start Date' />
                </FormItem>
            </GroupItem>

            <GroupItem caption='Contract Personnel'>
                <ContractPersonnel data={data.ContractPersonnel} editing={editing} updateField={updateObject} />
            </GroupItem>

            <GroupItem caption='Task Orders'>
                <DataGrid
                    id='myDataGrid'
                    dataSource={data.TaskOrders}
                    showBorders={true}
                    columnAutoWidth={true}
                    ref={gridRef}
                    key="TaskOrderId"
                    onEditorPreparing={onEditorPreparing}
                    onEditCanceled={onTaskOrderInserted}>
                    <Toolbar visible={editing} >
                        <Item name="addRowButton" showText="always" caption="Add Task Order" />
                    </Toolbar>
                    <Editing mode="popup" allowUpdating={true} allowAdding={true}  >
                        <Popup
                            showTitle={true}
                            title="Editing A Task Order"
                            visible={popupVisible}
                            Editing={popupVisible}
                        />
                        <DataGridForm
                            labelLocation="top"
                            customizeItem={customizeItem}>
                            <Item caption='General Information' itemType='group' colCount={2}>
                                <Item itemType='group'>
                                    <Item dataField='TaskOrderTypeId' caption='Task Order Type' labelMode='static' elementAttr={{ class: 'form-editor' }}
                                        editorType='dxSelectBox' />
                                    <Item dataField='Number' caption='Task Order Number' labelMode='static' elementAttr={{ class: 'form-editor' }} />
                                    <Item dataField='AwardDate' caption='Award Date' labelMode='static' elementAttr={{ class: 'form-editor' }}
                                        editorType="dxDateBox" editorOptions={dateEditorOptions} />
                                    <Item dataField='StartDate' id='StartDate' caption='Start Date' labelMode='static' elementAttr={{ class: 'form-editor' }}
                                        editorType="dxDateBox" editorOptions={dateEditorOptions} />
                                    <Item dataField='EndDate' caption='End Date' labelMode='static' elementAttr={{ class: 'form-editor' }}
                                        editorType="dxDateBox" editorOptions={dateEditorOptions} />
                                </Item>
                                <Item itemType='group'>
                                    <Item dataField='ModifyTaskOrderId' caption='Modification Number' labelMode='static' elementAttr={{ class: 'form-editor' }} />
                                    <Item caption='Region' dataField='RegionId' labelMode='static' elementAttr={{ class: 'form-editor' }} />
                                    <Item caption='Vendor Name' dataField='VendorName' labelMode='static' elementAttr={{ class: 'form-editor' }} />
                                    <Item caption='Product Description' dataField='ProductDescriptionId' labelMode='static' elementAttr={{ class: 'form-editor' }} />
                                    <Item caption='Product Code' dataField='ProductCodeId' labelMode='static' elementAttr={{ class: 'form-editor' }} />
                                </Item>
                            </Item>
                            <Item caption='Financial Codes' itemType='group' colCount={2}>
                                <Item itemType='group'>
                                    <Item
                                        dataField='FinancialCodes.AccountCode'
                                        labelMode='static' elementAttr={{ class: 'form-editor' }}
                                        onValueChange={updateField('FinancialCodes.AccountCode')}
                                    />
                                    <Item
                                        dataField='FinancialCodes.AccSequenceNumber'
                                        labelMode='static' elementAttr={{ class: 'form-editor' }}
                                        onValueChange={updateField('FinancialCodes.AccSequenceNumber')}
                                    />
                                    <Item
                                        dataField='FinancialCodes.AccObligatedAmount' editorOptions={{ format: 'currency' }}
                                        labelMode='static' elementAttr={{ class: 'form-editor' }}
                                        onValueChange={updateField('FinancialCodes.AccObligatedAmount')}
                                    />
                                </Item>
                                <Item itemType='group'>
                                    <Item
                                        dataField='FinancialCodes.TreasuryAccSymbol'
                                        labelMode='static' elementAttr={{ class: 'form-editor' }}
                                        onValueChange={updateField('FinancialCodes.TreasuryAccSymbol')}
                                    />
                                    <Item
                                        dataField='FinancialCodes.CageCode'
                                        caption='Cage Code'
                                        labelMode='static' elementAttr={{ class: 'form-editor' }}
                                        onValueChange={updateField('FinancialCodes.CageCode')}
                                    />
                                    <Item
                                        dataField='FinancialCodes.UniqueEntityNumber'
                                        caption='UEI (Unique Entity ID Number)'
                                        labelMode='static' elementAttr={{ class: 'form-editor' }}
                                        onValueChange={updateField('FinancialCodes.UniqueEntityNumber')}
                                    />
                                </Item>
                            </Item>
                        </DataGridForm>
                    </Editing>
                    {/* <Column dataField="Number" name="Edit" caption="" width={50} cellRender={(e) => renderTaskOrderForm(e)} /> */}
                    <Column type='buttons' visible={editing} alignment='left' cellRender={buttonRender}>
                        {/* <Button
              name='edit'
              elementAttr={{ "aria-label": "Edit Task Order" }}
            /> */}
                    </Column>
                    <Column dataField="Number" caption="Task Order Number">
                        <RequiredRule message="Task Order Number is required" />
                        <StringLengthRule min={2} message="Task Order Number length must be a minimum of 2 characters" />
                    </Column>
                    <Column dataField="TaskOrderTypeId" caption="Task Order Type" setCellValue={setCellValue}>
                        <Lookup
                            dataSource={displayTaskOrderTypes}
                            displayExpr="Name"
                            valueExpr="TaskOrderTypeId"
                        />
                        <RequiredRule message="Task Order Type is required" />
                    </Column>

                    <Column dataField="ModifyTaskOrderId" caption="Modification Number" setCellValue={setCellValue}>
                        <Lookup
                            dataSource={modTaskOrders}
                            displayExpr="Number"
                        />
                    </Column>
                    <Column dataField="AwardDate" caption="Award Date" dataType='date'>
                        <CustomRule message='Award Date must be equal to or greather than Contract Award Date or Contract Start Date.'
                            validationCallback={awardDateContractCompare} ignoreEmptyValue={true} reevaluate={true}></CustomRule>
                    </Column>
                    <Column dataField="StartDate" caption="Start Date" dataType='date' >
                        <RequiredRule message="Start Date is required" />
                        <CustomRule message='Start Date must be equal to or greather than Award Date or Contract Start Date.'
                            validationCallback={startDateTaskOrderCompare} reevaluate={true}></CustomRule>
                    </Column>
                    <Column dataField="EndDate" caption="End Date" dataType='date'>
                        <RequiredRule message="End Date is required" />
                        <CustomRule message='End Date must be equal to or greather than Start Date.'
                            validationCallback={endDateTaskOrderCompare} reevaluate={true}></CustomRule>
                    </Column>
                    <Column dataField="RegionId" caption="Region">
                        <Lookup
                            dataSource={regions}
                            displayExpr="RegionNumber"
                            valueExpr="RegionNumber"
                        />
                        <RequiredRule message="Description is required" />
                    </Column>
                    <Column dataField="VendorName" caption="Vendor Name">
                        <RequiredRule message="Vendor Name is required" />
                        <StringLengthRule min={3} message="Vendor Name must be a minimum of 3 characters" />
                    </Column>
                    <Column dataField="ProductDescriptionId" caption="Product Description">
                        <Lookup
                            dataSource={productDescriptions}
                            displayExpr="Description"
                            valueExpr="ProductDescriptionId"
                        />
                        <RequiredRule message="Description is required" />
                    </Column>
                    <Column dataField="ProductCodeId" caption="Product Code">
                        <Lookup
                            dataSource={productCodes}
                            displayExpr="Code"
                            valueExpr="ProductCodeId"
                        />
                        <RequiredRule message="Product Code is required" />
                    </Column>
                    <Column dataField="FinancialCodes.AccountCode" caption="Account Code" visible={false} />
                    <Column dataField="FinancialCodes.AccSequenceNumber" caption="Account Sequence Number" visible={false} />
                    <Column dataField="FinancialCodes.AccObligatedAmount" caption="Account Obligated Amount" visible={false} />
                    <Column dataField="FinancialCodes.TreasuryAccSymbol" caption="Treasury Account Symbol" visible={false} />
                    <Column dataField="FinancialCodes.CageCode" caption="Cage Code" visible={false} />
                    <Column dataField="FinancialCodes.UniqueEntityNumber" caption="UEI (Unique Entity ID Number)" visible={false}>
                        <StringLengthRule min={12} ignoreEmptyValue={true} message='Unique Entity Number must be a minimum length of 12' />
                    </Column>
                </DataGrid>
            </GroupItem>
        </Form>
    );
};