
import React from 'react';
import Button from 'devextreme-react/button';
import TabPanel from 'devextreme-react/tab-panel';
import List, { Item } from 'devextreme-react/list';
import dhsImage from '../../assets/FPS_Logo_20_0925-500.jpg';
import {
    ROUTE_BUILDING_SPECIFIC_ESTIMATE_REPORT,
    ROUTE_COST_ESTIMATE_REPORT,
    ROUTE_FY_COST_ESTIMATE,
    ROUTE_BUILDING_SPECIFIC_COST_ESTIMATE,
    ROUTE_AGENCY_SPECIFIC_COST_ESTIMATE,
    ROUTE_HELP,
    ROUTE_REGION_AND_BUILDING_DATA,
    ROUTE_ROLES,
    ROUTE_USERS,
    ROUTE_AUDIT_REPORT,
    ROUTE_ACTIVE_SESSIONS,
    ROUTE_USER_REPORT,
    ROUTE_CONTRACTS_PAGE,
    ROUTE_CONTRACT_COST_ESTIMATES,
    ROUTE_PSO_CONTRACT_SUMMARY_PAGE,
    ROUTE_POST_EXHIBITS_LIST_PAGE,
    ROUTE_CUSTOMERS,
    ROUTE_BILLING_ADJUSTMENTS,
    ROUTE_ADJUSTMENTS_REPORT,
    ROUTE_CRMA_DOWNLOAD,
    ROUTE_CRMA_UPLOAD,
    ROUTE_CRMA_HISTORY_GENERATED_BILL_BFC,
    ROUTE_CRMA_HISTORY_GENERATED_BILL_GSA,
    ROUTE_CRMA_HISTORY_GENERATED_BILL_REGION,
    ROUTE_CRMA_REPORT_DATA,
    ROUTE_WORKFLOW_TASKS,
    ROUTE_SPECIFIC_COST_ESTIMATE_SUMMARY,
} from '../../routes/Routes';
import MenuItem, { PROFILE_MENU, BUILDINGS_MENU, CONTRACTS_MENU, BILLINGS_MENU, FYCOSTS_MENU, ADMIN_MENU, HELP_MENU } from '../MenuItem';
import { Divider } from '@mui/material';
import { generateUID } from '../../util/_helpers';
import './Welcome.scss';

const Welcome = () => {
    const _user = JSON.parse(localStorage.getItem("user"));
    const modules = _user.role?.ModulePermissions?.map(module => { if (module.Read) return module.Page });

    let explainToDhsWhyWeStillHaveTheseButtonsWhenNotAdmin = " (Role doesn\'t have permission, left for testing only)";
    const labelsArray = [
        {
            menu: PROFILE_MENU,
            name: 'Email: ' + _user.email,
            path: '#',
            allowed: true,
            id: "email"
        },
        {
            menu: PROFILE_MENU,
            name: 'Role: ' + _user.role.Name,
            path: '#',
            allowed: true,
            id: "role"
        },
        {
            menu: PROFILE_MENU,
            name: 'Regions: ' + _user.Regions,
            path: '#',
            allowed: true,
            id: "regions"
        },
        {
            menu: PROFILE_MENU,
            name: ROUTE_WORKFLOW_TASKS.title,
            path: ROUTE_WORKFLOW_TASKS.withSlash,
            allowed: modules?.includes(ROUTE_WORKFLOW_TASKS.module),
            id: "workflow-tasks"
        },
        {
            menu: BUILDINGS_MENU,
            name: ROUTE_REGION_AND_BUILDING_DATA.title,
            path: ROUTE_REGION_AND_BUILDING_DATA.withSlash,
            allowed: modules?.includes(ROUTE_REGION_AND_BUILDING_DATA.module),
            id: "region-building-data"
        },
        {
            menu: BUILDINGS_MENU,
            name: ROUTE_CUSTOMERS.title,
            path: ROUTE_CUSTOMERS.withSlash,
            allowed: modules?.includes(ROUTE_CUSTOMERS.module),
            id: "customers"
        },
        {
            menu: BUILDINGS_MENU,
            name: ROUTE_BUILDING_SPECIFIC_ESTIMATE_REPORT.title,
            path: ROUTE_BUILDING_SPECIFIC_ESTIMATE_REPORT.withSlash,
            allowed: modules?.includes(ROUTE_BUILDING_SPECIFIC_ESTIMATE_REPORT.module),
            id: "building-specific-estimate-report"
        },
        {
            menu: FYCOSTS_MENU,
            name: ROUTE_FY_COST_ESTIMATE.title,
            path: ROUTE_FY_COST_ESTIMATE.withSlash,
            allowed: modules?.includes(ROUTE_FY_COST_ESTIMATE.module),
            id: "fy-cost-estimate"
        },
        {
            menu: CONTRACTS_MENU,
            name: ROUTE_CONTRACTS_PAGE.title,
            path: ROUTE_CONTRACTS_PAGE.withSlash,
            allowed: modules?.includes(ROUTE_CONTRACTS_PAGE.module),
            id: "contracts-page"
        },
        {
            menu: CONTRACTS_MENU,
            name: ROUTE_CONTRACT_COST_ESTIMATES.title,
            path: ROUTE_CONTRACT_COST_ESTIMATES.withSlash,
            allowed: modules?.includes(ROUTE_CONTRACT_COST_ESTIMATES.module),
            id: "contract-cost-estimates"
        },
        {
            menu: FYCOSTS_MENU,
            name: ROUTE_COST_ESTIMATE_REPORT.title,
            path: ROUTE_COST_ESTIMATE_REPORT.withSlash,
            allowed: modules?.includes(ROUTE_COST_ESTIMATE_REPORT.module),
            id: "cost-estimate-report"
        },
        {
            menu: FYCOSTS_MENU,
            name: ROUTE_BUILDING_SPECIFIC_COST_ESTIMATE.title,
            path: ROUTE_BUILDING_SPECIFIC_COST_ESTIMATE.withSlash,
            allowed: modules?.includes(ROUTE_FY_COST_ESTIMATE.module),
            id: "building-specific-cost-estimate"
        },
        {
            menu: FYCOSTS_MENU,
            name: ROUTE_AGENCY_SPECIFIC_COST_ESTIMATE.title,
            path: ROUTE_AGENCY_SPECIFIC_COST_ESTIMATE.withSlash,
            allowed: modules?.includes(ROUTE_FY_COST_ESTIMATE.module),
            id: "agency-specific-cost-estimate"
        },
        {
            menu: FYCOSTS_MENU,
            name: ROUTE_SPECIFIC_COST_ESTIMATE_SUMMARY.title,
            path: ROUTE_SPECIFIC_COST_ESTIMATE_SUMMARY.withSlash,
            allowed: modules?.includes(ROUTE_FY_COST_ESTIMATE.module),
            id: "specific-cost-estimate-summary"
        },
        {
            menu: FYCOSTS_MENU,
            name: ROUTE_COST_ESTIMATE_REPORT.title,
            path: ROUTE_COST_ESTIMATE_REPORT.withSlash,
            allowed: modules?.includes(ROUTE_COST_ESTIMATE_REPORT.module)
        },        
        {
            menu: BILLINGS_MENU,
            name: ROUTE_BILLING_ADJUSTMENTS.title,
            path: ROUTE_BILLING_ADJUSTMENTS.withSlash,
            allowed: modules?.includes(ROUTE_BILLING_ADJUSTMENTS.module),
            id: "billing-adjustments"
        },
        {
            menu: BILLINGS_MENU,
            name: ROUTE_ADJUSTMENTS_REPORT.title,
            path: ROUTE_ADJUSTMENTS_REPORT.withSlash,
            allowed: modules?.includes(ROUTE_ADJUSTMENTS_REPORT.module),
            id: "adjustments-report"
        },
        {
            menu: BILLINGS_MENU,
            name: ROUTE_CRMA_UPLOAD.title,
            path: ROUTE_CRMA_UPLOAD.withSlash,
            allowed: modules?.includes(ROUTE_CRMA_UPLOAD.module),
            id: "crma-upload"
        },
        {
            menu: BILLINGS_MENU,
            name: ROUTE_CRMA_DOWNLOAD.title,
            path: ROUTE_CRMA_DOWNLOAD.withSlash,
            allowed: modules?.includes(ROUTE_CRMA_DOWNLOAD.module),
            id: "crma-download"
        },
        {
            menu: BILLINGS_MENU,
            name: ROUTE_CRMA_HISTORY_GENERATED_BILL_BFC.title,
            path: ROUTE_CRMA_HISTORY_GENERATED_BILL_BFC.withSlash,
            allowed: modules?.includes(ROUTE_CRMA_HISTORY_GENERATED_BILL_BFC.module),
            id: "crma-history-generated-bill-bfc"
        },
        {
            menu: BILLINGS_MENU,
            name: ROUTE_CRMA_HISTORY_GENERATED_BILL_GSA.title,
            path: ROUTE_CRMA_HISTORY_GENERATED_BILL_GSA.withSlash,
            allowed: modules?.includes(ROUTE_CRMA_HISTORY_GENERATED_BILL_GSA.module),
            id: "crma-history-generated-bill-gsa"
        },
        {
            menu: BILLINGS_MENU,
            name: ROUTE_CRMA_HISTORY_GENERATED_BILL_REGION.title,
            path: ROUTE_CRMA_HISTORY_GENERATED_BILL_REGION.withSlash,
            allowed: modules?.includes(ROUTE_CRMA_HISTORY_GENERATED_BILL_REGION.module),
            id: "crma-history-generated-bill-region"
        },
        /**
         * @todo
         * Disable this module and fix issue with monthAndYear date string format. Will need to verify that the date string format being persisted in the table matches what is coming from React.
         */
        // {
        //     menu: BILLINGS_MENU,
        //     name: ROUTE_CRMA_REPORT_DATA.title,
        //     path: ROUTE_CRMA_REPORT_DATA.withSlash,
        //     allowed: modules?.includes(ROUTE_CRMA_REPORT_DATA.module)
        // },
        {
            menu: ADMIN_MENU,
            name: ROUTE_USERS.title,
            path: ROUTE_USERS.withSlash,
            allowed: modules?.includes(ROUTE_USERS.module),
            id: "admin-users"
        },
        {
            menu: ADMIN_MENU,
            name: ROUTE_ROLES.title,
            path: ROUTE_ROLES.withSlash,
            allowed: modules?.includes(ROUTE_ROLES.module),
            id: "admin-roles"
        },
        {
            menu: ADMIN_MENU,
            name: ROUTE_ACTIVE_SESSIONS.title,
            path: ROUTE_ACTIVE_SESSIONS.withSlash,
            allowed: modules?.includes(ROUTE_ACTIVE_SESSIONS.module),
            id: "active-sessions"
        },
        {
            menu: ADMIN_MENU,
            name: ROUTE_AUDIT_REPORT.title,
            path: ROUTE_AUDIT_REPORT.withSlash,
            allowed: modules?.includes(ROUTE_AUDIT_REPORT.module),
            id: "audit-report"
        },
        {
            menu: ADMIN_MENU,
            name: ROUTE_USER_REPORT.title,
            path: ROUTE_USER_REPORT.withSlash,
            allowed: modules?.includes(ROUTE_USER_REPORT.module),
            id: "user-report"
        },
        {
            menu: CONTRACTS_MENU,
            name: ROUTE_PSO_CONTRACT_SUMMARY_PAGE.title,
            path: ROUTE_PSO_CONTRACT_SUMMARY_PAGE.withSlash,
            allowed: modules?.includes(ROUTE_PSO_CONTRACT_SUMMARY_PAGE.module),
            id: "pso-contract-summary"
        },
        {
            menu: CONTRACTS_MENU,            
            name: ROUTE_POST_EXHIBITS_LIST_PAGE.title,
            path: ROUTE_POST_EXHIBITS_LIST_PAGE.withSlash,
            allowed: modules?.includes(ROUTE_POST_EXHIBITS_LIST_PAGE.module)
        },
        {
            menu: HELP_MENU,             
            name: ROUTE_HELP.title,
            path: ROUTE_HELP.withSlash,
            allowed: modules?.includes(ROUTE_HELP.module),
            id: "help"
        }
    ];

    const modifiedArray = generateUID(labelsArray);
    const labelsAllowed = modifiedArray.filter(({ allowed }) => allowed == true);
    const menuArray = Map.groupBy(labelsAllowed, ({ menu }) => menu);
    const menuList = Array.from(menuArray, ([key, value]) => (
        { 
            key, 
            icon: key.icon, 
            title: key.title, 
            modules: value 
        }
    )).sort((a, b) => a['key'].key - b['key'].key);

    return (
        <List width={1000}
            focusStateEnabled={false}
            hoverStateEnabled={false}
            style={{ alignContent: 'center'}}>
            <Item key={1}>
                <img
                    src={dhsImage}
                    alt="Federal Protective Service logo with small Department of Homeland Security emblem."
                    style={{
                        display: 'block',
                        margin: 'auto',
                        maxWidth: '250px',
                        // marginRight: '2px'
                    }}
                />
            </Item>
            <Item key={2}>
                <div style={{textAlign:'center'}}>
                    <h1 style={{color: '#06152B'}}>(RMS) Revenue Management System</h1>
                </div>
            </Item>
            {
                <Item key={3} style={{justifyContent: 'center'}} >
                    <TabPanel
                        className="dx-theme-background-color"
                        width="80%"
                        height={410}
                        animationEnabled={false}
                        swipeEnabled={true}
                        dataSource={menuList}
                        tabsPosition="left"
                        stylingMode="primary"
                        iconPosition="top"
                        itemComponent={MenuItem}
                        onTitleRendered={e => e.itemElement.id = e.itemData.title.split(" ").join("")}
                        loop={true}
                    />
                </Item>
            }
            <Divider />
            {
                labelsArray.map(({ id, path, name, allowed }) => {
                    if (allowed) {
                        return;
                    }
                    return(
                    <Item key={id + 3}>
                        <div>
                            <span key={id} id={id}>
                                <Button
                                    color='warning'
                                    sx={{ width: '85%' }}
                                    variant="outlined"
                                    onClick={() => { console.log("No permission, left for testing") }}>
                                    {name + explainToDhsWhyWeStillHaveTheseButtonsWhenNotAdmin}
                                </Button>
                            </span>
                        </div>
                    </Item>
                )
            })
            }
        </List>
    );
}

export default Welcome;