import React, { useState, useEffect, useRef, useCallback } from "react";
import { BaseButton } from "../../components/BaseButton/BaseButton";
import { FormTextBox } from '../../components/FormTextBox/FormTextBox';
import { ROUTE_CREATE_EDIT_POST_EXHIBIT, ROUTE_POST_EXHIBITS_LIST_PAGE } from "../../routes/Routes";
import "../css/PostExhibits.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { ColCountByScreen, Form, Item as FormItem, GroupItem, TabbedItem, TabPanelOptions, Tab, Item, RequiredRule, CustomRule } from "devextreme-react/form";
import GetFetch from "../../hooks/GetFetch";
import { DateBox, SelectBox, TextBox, ValidationGroup, Validator } from "devextreme-react";
import { ToolbarForm } from "../../components/toolbar-form/toolbar-form";
import DataGridPost from "./DataGridPost";
import PutPostPatchFetch from "../../hooks/PutPostPatchFetch";
import { FormDateBox } from "../../components/FormDateBox/FormDateBox";
import IsNullOrEmpty from "../../components/IsNullOrEmpty";
import notify from "devextreme/ui/notify";
import { LoadingIndicatorCircle } from "../../components/Loading";
import PersonnelTypeIds from "../../Enums/personnelTypeIds";

const URL_contracts = '/v1/Contract/GetContracts';
const URL_clonePostExhibit = '/v1/PostExhibit/ClonePostExhibit/'; //+ PostExhibitId
const URL_updatePostExhibit = '/v1/PostExhibit/UpdatePostExhibit';
const URL_addPostExhibit = '/v1/PostExhibit/AddPostExhibit';
const URL_getPostExhibitStatuses = '/v1/PostExhibit/GetPostExhibitStatuses';
const URL_getPostExhibitsById = '/v1/PostExhibit/GetPostExhibitById/'; //+PostExhbitId

/** @type {import("../../types/postExhibit").PostExhibit} */
const defaultPostExhibit = {
    PostExhibitId: 0,
    Posts: []
}

/** @typedef {import('../../../src/types/postExhibit').PostExhibit} PostExhibit */

export default function PostExhibitForm() {
    const navigate = useNavigate();
    const location = useLocation();
    const [taskOrders, setTaskOrders] = useState([]);
    const [contracts, setContracts] = useState([]);
    const [selectedContractId, setSelectedContractId] = useState(null);
    const [selectedTaskOrderNumber, setSelectedTaskOrderNumber] = useState(null);
    const [editing, setEditing] = useState(false);
    const [isNewPostExhibit, setIsNewPostExhibit] = useState(false);
    const dataRef = useRef(null);
    const [postExhibitFormData, setPostExhibitFormData] = useState(defaultPostExhibit);
    const [postExhibitFormDataCopy, setPostExhibitFormDataCopy] = useState(null);
    const [postExhibitStatuses, setPostExhibitStatuses] = useState(null);
    /**@type {[PostExhibit, React.Dispatch<React.SetStateAction<PostExhibit>>]} */
    const [postExhibit, setPostExhibit] = useState(null);
    const [loading, setLoading] = useState(true);

    const minDate = new Date(1900, 0, 1);

    const isFormChanged = (original, current) => {
        return JSON.stringify(original) !== JSON.stringify(current);
    };

    useEffect(() => {

        const initializePostExhibit = async (state) => {
            try {
                const results = await Promise.allSettled([
                    fetchData(URL_getPostExhibitStatuses),
                    fetchData(URL_contracts),
                ]);

                setPostExhibitStatuses(
                    results[0].status === "fulfilled" ? results[0].value : []
                );
                setContracts(results[1].status === "fulfilled" ? results[1].value : []);

                if (!state) {
                    // Initialize new post exhibit
                    return {
                        isEditing: true,
                        isNewPostExhibit: true,
                        postExhibitFormData: defaultPostExhibit,
                    };
                }

                const {
                    isEditing,
                    isNewPostExhibit,
                    postExhibitId,
                    postExhibit: statePostExhibit,
                    postExhibitFormData: statePostExhibitFormData,
                } = state;

                let savedPostExhibit = null;

                // Fetch the saved postExhibit from the DB if not available in state
                if (postExhibitId && !statePostExhibit) {
                    savedPostExhibit = await fetchData(
                        `${URL_getPostExhibitsById}${postExhibitId}`
                    );
                }

                const resolvedPostExhibit = statePostExhibit ?? savedPostExhibit ?? null;

                let currentPostExhibitForm = statePostExhibitFormData;

                // Compare and selectively merge
                if (resolvedPostExhibit && statePostExhibitFormData) {
                    if (isFormChanged(resolvedPostExhibit, statePostExhibitFormData)) {
                        currentPostExhibitForm = {
                            ...resolvedPostExhibit,
                            ...Object.keys(statePostExhibitFormData).reduce((acc, key) => {
                                // Only override fields that have meaningful values
                                if (!IsNullOrEmpty(statePostExhibitFormData[key])) {
                                    acc[key] = statePostExhibitFormData[key];
                                }
                                return acc;
                            }, {}),
                            // Explicitly handle Posts array (or any nested fields needing merging)
                            Posts: statePostExhibitFormData.Posts?.length
                                ? statePostExhibitFormData.Posts
                                : resolvedPostExhibit.Posts,
                        };

                    }
                } else if (resolvedPostExhibit) {
                    currentPostExhibitForm = resolvedPostExhibit;
                }

                return {
                    postExhibit: resolvedPostExhibit,
                    postExhibitFormData: currentPostExhibitForm,
                    contractId: resolvedPostExhibit?.TaskOrder?.ContractId || null,
                    taskOrderNumber: resolvedPostExhibit?.TaskOrderNumber || null,
                    isEditing: isEditing ?? false,
                    isNewPostExhibit: isNewPostExhibit ?? false,
                };
            } catch (error) {
                console.error("Error initializing post exhibit: ", error);
                return {};
            }
        };

        const fetchDataAndInitialize = async () => {
            setLoading(true);

            const state = location?.state;
            const {
                postExhibit,
                postExhibitFormData,
                contractId,
                taskOrderNumber,
                isEditing,
                isNewPostExhibit,
            } = await initializePostExhibit(state);

            setPostExhibit(postExhibit);
            setPostExhibitFormData(postExhibitFormData);
            setPostExhibitFormDataCopy(postExhibitFormData);
            setSelectedContractId(contractId);
            setSelectedTaskOrderNumber(taskOrderNumber);
            setEditing(isEditing);
            setIsNewPostExhibit(isNewPostExhibit);

            // Populate task orders if contractId exists
            if (contractId) {
                const contract = contracts.find(
                    (contract) => contract.ContractId === contractId
                );
                if (contract?.TaskOrders) {
                    setTaskOrders(contract.TaskOrders);
                }
            }

            setLoading(false);
        };

        fetchDataAndInitialize();
    }, []);

    useEffect(() => {
        if (selectedContractId && contracts.length > 0) {
            const contract = contracts.find(c => c.ContractId === selectedContractId);
            if (contract?.TaskOrders) {
                setTaskOrders(contract.TaskOrders);
            }
        }
    }, [selectedContractId, contracts]);

    useEffect(() => {
        if (!IsNullOrEmpty(selectedContractId) && !IsNullOrEmpty(postExhibitFormData?.TaskOrder?.TaskOrderNumber)) {
            setSelectedTaskOrderNumber(postExhibitFormData.TaskOrder.TaskOrderNumber);
        }
    }, [selectedContractId, postExhibitFormData]);

    const fetchData = async (url) => {
        const { Message, Success } = await GetFetch(url);
        return Success ? Message : [];
    }

    const updateField = useCallback((field, value) => {
        setPostExhibitFormData((prevData) => ({
            ...prevData,
            [field]: value,
        }));
    }, []);

    const handleContractSelection = useCallback((selectedItem) => {
        if (selectedItem && selectedItem.ContractId !== selectedContractId) {
            setSelectedContractId(selectedItem.ContractId);
            const contract = contracts.find(contract => contract.ContractId === selectedItem.ContractId);
            if (contract && contract.TaskOrders) {
                setTaskOrders(contract.TaskOrders);
            } else {
                setTaskOrders([]);
            }

            updateField('ContractId', selectedItem.ContractId);
        }
    }, [contracts, selectedContractId, updateField]);

    const handleTaskOrderSelection = useCallback((selectedItem) => {
        if (selectedItem && selectedItem.Number !== selectedTaskOrderNumber) {
            setSelectedTaskOrderNumber(selectedItem.Number);
            updateField('TaskOrderNumber', selectedItem.Number);
            updateField('TaskOrderId', selectedItem.TaskOrderId);
            updateField('TaskOrder', selectedItem);
        }
    }, [selectedTaskOrderNumber, updateField]);

    const onClonePostExhibit = async () => {
        try {
            const { Errors, Message, Success } = await PutPostPatchFetch(
                `${URL_clonePostExhibit}${postExhibitFormData.PostExhibitId}`,
                'POST',
                null
            );

            if (Success) {
                const clonedPostExhibitId = Message.PostExhibitId;
                showMessage(`Success! Cloned Post Exhibit ID: ${clonedPostExhibitId}`, true);
            } else {
                showMessage('Error cloning post exhibit', false);
            }
        } catch (error) {
            console.error('Error cloning post exhibit:', error);
            showMessage('An unexpected error occurred while cloning', false);
        }
    }

    const showMessage = (message, success, displayTime = 7500, width = 500) => {
        notify(
            {
                message: message,
                width: width,
                position: {
                    at: 'top',
                    my: 'top',
                    of: '#container'
                }
            },
            success ? "success" : 'error',
            displayTime
        )
    }

    const formatForSave = (obj) => {
        // Handle arrays to prevent conversion to objects
        if (Array.isArray(obj)) {
            return obj.map(item => formatForSave(item));
        }

        const formattedObj = { ...obj };

        if (!Object.hasOwn(formattedObj, 'PostExhibitId')) {
            formattedObj.PostExhibitId = 0;
        }

        // Remove ClonePostExhibitId if null
        if (Object.hasOwn(formattedObj, 'ClonedPostExhibitId') && formattedObj.ClonedPostExhibitId === null) {
            delete formattedObj.ClonedPostExhibitId;
        }

        // Replace null IDs with 0
        Object.keys(formattedObj).forEach(key => {
            if (key.toLowerCase().endsWith('id') && formattedObj[key] === null) {
                formattedObj[key] = 0;
            }
        });

        // Remove TaskOrder object
        if (formattedObj.TaskOrder) {
            formattedObj.TaskOrder = null;
        }
        // Remove Building Object
        if (formattedObj.Building) {
            formattedObj.Building = null;
        }

        // Handle nested objects
        Object.keys(formattedObj).forEach(key => {
            if (typeof formattedObj[key] === 'object' && formattedObj[key] !== null) {
                formattedObj[key] = formatForSave(formattedObj[key]);
            }
        });

        return formattedObj;
    }
    const onSaveClick = async ({ validationGroup }) => {
        if (!validationGroup.validate().isValid) {
            showMessage('Please complete and correct all required fields!', false)
            return;
        }

        const preparedPostExhibitData = formatForSave(postExhibitFormData);

        setEditing(false);

        try {
            const isNewPostExhibit = IsNullOrEmpty(preparedPostExhibitData.PostExhibitId) || preparedPostExhibitData.PostExhibitId === 0;
            const saveUrl = isNewPostExhibit ? URL_addPostExhibit : URL_updatePostExhibit;
            const { Success, Errors, Message } = await PutPostPatchFetch(
                saveUrl,
                'POST',
                preparedPostExhibitData
            );

            if (Success) {
                showMessage(
                    isNewPostExhibit ? 'New Post Exhibit created successfully' : 'Post Exhibit updated successfully',
                    Success,
                    3000
                );

                navigate(ROUTE_POST_EXHIBITS_LIST_PAGE.withSlash);
            } else {
                showMessage('Failed to save Post Exhibit: ' + Errors[0].Message, false, 5000);
                setEditing(true);
            }
        } catch (error) {
            showMessage('An unexpected error occurred while saving', false, 5000);
            setEditing(true);
            setError(true);
        }
    }

    const handleEditClick = () => {
        if (editing === false && postExhibitFormData) {
            dataRef.current = postExhibitFormData;
        } else {
            dataRef.current = undefined;
        }
        setEditing(!editing);
    }

    const onCancelClick = () => {
        setPostExhibitFormData(isNewPostExhibit ? defaultPostExhibit : postExhibit);
        handleEditClick();
    }

    const getTaskOrderTypeString = () => {
        const taskOrderTypeId = postExhibitFormData?.TaskOrder?.TaskOrderTypeId || 0;
        switch (taskOrderTypeId) {
            case 1:
                return "Base";
            case 2:
                return "Option";
            case 3:
                return "Modification";
            default:
                return "";
        }
    }

    const formatDate = (date) => {
        if (!date) return null;
        const formattedDate = new Date(date);
        return formattedDate.toISOString().split('T')[0];
    }

    const navigateToPostExhibitList = () => {
        navigate(ROUTE_POST_EXHIBITS_LIST_PAGE.withSlash, {
            replace: true
        });
    }

    return (
        <div id="container">
            <h1>{"Post Exhibit Details"}</h1>
            <div>
                {!loading && !isNewPostExhibit &&
                    <BaseButton
                        label={"Clone Post Exhibit"}
                        onClick={onClonePostExhibit}
                        variant={"contained"}
                        disabled={editing}
                    />
                }
            </div>
            {loading && <LoadingIndicatorCircle message='Loading Post Exhibit Details' />}
            {!loading &&
                <div className="inline_div--form-wrapper">
                    <ValidationGroup>
                        <ToolbarForm
                            toggleEditing={handleEditClick}
                            onSaveClick={onSaveClick}
                            onBackClick={navigateToPostExhibitList}
                            editing={editing}
                            onCancelClick={onCancelClick} />
                        <Form>
                            <GroupItem
                                caption={"Post Exhibit ID: " + postExhibitFormData?.PostExhibitId} >
                                <TabbedItem >
                                    <TabPanelOptions deferRendering={false} />
                                    <Tab title="Contract Summary" >
                                        {/* Contract Number, contract Personnel*/}
                                        <GroupItem colCount={4}>
                                            <FormItem>
                                                <SelectBox
                                                    inputAttr={{ 'aria-label': 'ContractId' }}
                                                    label='Contract Id'
                                                    dataSource={contracts}
                                                    displayExpr={'Number'}
                                                    valueExpr={'ContractId'}
                                                    value={selectedContractId}
                                                    onSelectionChanged={(e) => handleContractSelection(e.selectedItem)}
                                                    readOnly={!editing}
                                                    searchEnabled={true}
                                                />
                                            </FormItem>
                                            {postExhibitFormData?.ContractPersonnel && postExhibitFormData.ContractPersonnel.map((personnel, index) => {
                                                return (
                                                    <GroupItem colCount={'auto'} key={index}>
                                                        <FormItem colSpan={'auto'} key={index + 1}>
                                                            <div><b>{PersonnelTypeIds.getShortNameById(personnel.PersonnelTypeId)}</b></div>
                                                        </FormItem>
                                                        <FormItem key={index + 2}>
                                                            <FormTextBox
                                                                label='Officer Full Name'
                                                                value={personnel.OfficerFullName}
                                                                isEditing={false}
                                                            />
                                                        </FormItem>
                                                    </GroupItem>
                                                )
                                            })}
                                        </GroupItem>
                                    </Tab>
                                    <Tab title="Task Order Summary" >
                                        {/* T.O Number, start date, end date, vendor, region, type*/}
                                        <GroupItem colCount={4}>
                                            <FormItem>
                                                <SelectBox
                                                    inputAttr={{ 'aria-label': 'Task Order Number' }}
                                                    label='Task Order Number'
                                                    dataSource={taskOrders}
                                                    displayExpr={'Number'}
                                                    valueExpr={'Number'}
                                                    value={selectedTaskOrderNumber}
                                                    onSelectionChanged={(e) => handleTaskOrderSelection(e.selectedItem)}
                                                    readOnly={!editing}
                                                    searchEnabled={true}
                                                    disabled={!taskOrders.length}
                                                />
                                            </FormItem>
                                            <FormItem>
                                                <FormDateBox
                                                    label='Start Date'
                                                    value={postExhibitFormData?.TaskOrder?.StartDate}
                                                    readOnly={true}
                                                    onValueChange={e => {
                                                        const formattedDate = formatDate(e)
                                                        updateField('TaskOrder.StartDate', formattedDate)
                                                    }}
                                                />
                                            </FormItem>
                                            <FormItem>
                                                <FormDateBox
                                                    label='End Date'
                                                    value={postExhibitFormData?.TaskOrder?.EndDate}
                                                    readOnly={true}
                                                    onValueChange={e => {
                                                        const formattedDate = formatDate(e)
                                                        updateField('TaskOrder.EndDate', formattedDate)
                                                    }}
                                                />
                                            </FormItem>

                                            <FormItem>
                                                <FormTextBox
                                                    label='Vendor'
                                                    value={postExhibitFormData?.TaskOrder?.VendorName}
                                                    isEditing={false}
                                                    onValueChange={e => updateField('TaskOrder.VendorName', e)}
                                                />
                                            </FormItem>
                                            <FormItem>
                                                <FormTextBox
                                                    label='Region'
                                                    value={postExhibitFormData?.TaskOrder?.RegionId}
                                                    isEditing={false}
                                                    onValueChange={e => updateField('TaskOrder.RegionId', e)}
                                                />
                                            </FormItem>
                                            <FormItem>
                                                <FormTextBox
                                                    label='Type'
                                                    value={getTaskOrderTypeString()}
                                                    isEditing={false}
                                                    onValueChange={e => updateField('TaskOrder.TaskOrderTypeId', e)}
                                                />
                                            </FormItem>
                                        </GroupItem>
                                    </Tab>
                                    <Tab title="Post Exhibit" >
                                        <GroupItem colCount={4}>
                                            <FormItem>
                                                <TextBox
                                                    label='Name'
                                                    value={postExhibitFormData?.Name}
                                                    readOnly={!editing}
                                                    onValueChange={e => updateField('Name', e)}
                                                />
                                            </FormItem>
                                            <FormItem>
                                                <TextBox
                                                    label='Fiscal Year'
                                                    value={postExhibitFormData?.FiscalYear}
                                                    readOnly={!editing}
                                                    onValueChange={e => updateField('FiscalYear', e)}
                                                />
                                            </FormItem>
                                            <FormItem>
                                                <SelectBox
                                                    inputAttr={{ 'aria-label': 'Status' }}
                                                    label='Status'
                                                    dataSource={postExhibitStatuses}
                                                    displayExpr={'Name'}
                                                    valueExpr={'PostExhibitStatusId'}
                                                    value={postExhibitFormData?.PostExhibitStatusId}
                                                    onSelectionChanged={(e) => updateField('PostExhibitStatusId', e.selectedItem.PostExhibitStatusId)}
                                                    readOnly={!editing}
                                                    searchEnabled={true}
                                                >
                                                    <Validator>
                                                        <RequiredRule message="Status is required" />
                                                    </Validator>
                                                </SelectBox>
                                            </FormItem>
                                            <FormItem>
                                                <DateBox
                                                    type='datetime'
                                                    label='Start Date'
                                                    showDropDownButton={true}
                                                    min={minDate}
                                                    value={postExhibitFormData?.StartDate}
                                                    onValueChanged={e => {
                                                        const formattedDate = formatDate(e.value)
                                                        updateField('StartDate', formattedDate)
                                                    }}
                                                    displayFormat={"MM/dd/yyyy"}
                                                    placeholder='MM/dd/yyyy'
                                                    readOnly={!editing}
                                                    useMaskBehavior={true}
                                                >
                                                    <Validator>
                                                        <RequiredRule message="Start Date is required" />
                                                    </Validator>
                                                </DateBox>
                                            </FormItem>
                                            <FormItem>
                                                <DateBox
                                                    type='datetime'
                                                    label='End Date'
                                                    showDropDownButton={true}
                                                    min={minDate}
                                                    value={postExhibitFormData?.EndDate}
                                                    isEditing={editing}
                                                    onValueChanged={e => {
                                                        const formattedDate = formatDate(e.value)
                                                        updateField('EndDate', formattedDate)
                                                    }}
                                                    displayFormat={"MM/dd/yyyy"}
                                                    placeholder='MM/dd/yyyy'
                                                    readOnly={!editing}
                                                    useMaskBehavior={true}
                                                >
                                                    <Validator>
                                                        <RequiredRule message="End Date is required" />
                                                        <CustomRule
                                                            message="End Date must be after Start Date"
                                                            validationCallback={() => {
                                                                if (!postExhibitFormData?.StartDate || !postExhibitFormData?.EndDate) {
                                                                    return true;
                                                                }
                                                                return postExhibitFormData.EndDate > postExhibitFormData.StartDate;
                                                            }}
                                                        />
                                                    </Validator>

                                                </DateBox>
                                            </FormItem>
                                            <FormItem>
                                                <FormTextBox
                                                    label='Cloned PostExhibit ID'
                                                    value={postExhibitFormData?.ClonedPostExhibitId}
                                                    isEditing={false}
                                                    onValueChange={e => updateField('ClonedPostExhibitId', e)}
                                                />
                                            </FormItem>
                                        </GroupItem>
                                    </Tab>
                                </TabbedItem>
                            </GroupItem>
                            <GroupItem caption="Posts">
                                <DataGridPost postExhibit={postExhibit} postExhibitFormData={postExhibitFormData} editing={editing} />
                            </GroupItem>
                        </Form>
                    </ValidationGroup>
                </div>
            }
        </div>
    )
}