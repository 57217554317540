import React, { useEffect, useState } from "react";
import DataGrid, {
    Column,
    FilterRow,
    Item,
    KeyboardNavigation,
    Pager,
    Paging,
    SearchPanel,
    Toolbar,
} from 'devextreme-react/data-grid';
import CustomPreparedHeaderCell from "../components/CustomPreparedHeaderCell";
import { allowedPageSizes } from "../components/GlobalDataGridConfigurations";
import GetFetch from "../hooks/GetFetch";
import { useNavigate } from "react-router-dom";
import { ROUTE_POST_EXHIBITS_LIST_PAGE, ROUTE_CREATE_EDIT_POST_EXHIBIT } from "../routes/Routes";
import "./css/PostExhibits.scss";

const URL_postExhibits = '/v1/PostExhibit/GetPostExhibits';

export default function PostExhibitsList() {
    const [error, setError] = useState();
    const [postExhibits, setPostExhibits] = useState(null);
    const [formattedPostExhibits, setFormattedPostExhibits] = useState();
    const navigate = useNavigate();
    const { title } = ROUTE_POST_EXHIBITS_LIST_PAGE;

    useEffect(() => {
        fetchPostExhibits();
    }, []);

    const fetchPostExhibits = async () => {
        try {
            const { Errors, Message, Success } =
                await GetFetch(URL_postExhibits);
            setError(Success === false);
            if (Success) {
                setPostExhibits(Message);
                const data = Message.map(
                    ({
                        CLIN,
                        PostExhibitId,
                        TaskOrderId,
                        FiscalYear,
                        StartDate,
                        EndDate,
                        ClonedPostExhibitId,
                        TaskOrder,
                        FeeType: { FeeTypeName } = {},
                        PostExhibitStatus: { Name: StatusName } = {}
                    }) => {
                        const {
                            VendorName = null,
                            ContractId = null,
                            RegionId = null,
                            TaskOrderTypeId = null,                            
                            PostExhibit: {
                                StartDate: PostExhibitStartDate = null,
                                EndDate: PostExhibitEndDate = null,
                                Building: {
                                    Name: Building_Name = null,
                                    RegionNumber = null,
                                    SequenceNumber: BuildingSequenceNumber = null,
                                    BuildingId = null
                                } = {}
                            } = {}
                        } = TaskOrder || {};

                        return {
                            CLIN,
                            PostExhibitId,
                            TaskOrderId,
                            FiscalYear,
                            Building_Name,
                            BuildingId,
                            BuildingSequenceNumber,
                            StartDate,
                            EndDate,
                            RegionId,
                            VendorName,
                            ContractId,
                            PostExhibitStartDate,
                            PostExhibitEndDate,
                            FeeTypeName,
                            StatusName,
                            TaskOrderTypeId,
                            ClonedPostExhibitId
                        };
                    }
                );
                setFormattedPostExhibits(data);
            }
        } catch (error) {
            console.error(error);
        }
    }

    const navigateToAddPostExhibit = () => {
        navigate(ROUTE_CREATE_EDIT_POST_EXHIBIT.withSlash)
    }    

    const navigateToEditPostExhibit = (postExhibitId) => {
        navigate(ROUTE_CREATE_EDIT_POST_EXHIBIT.withSlash, {
            state: {
                postExhibitId: postExhibitId,
            }
        });
    }

    return (
        <>
            <h1>{title}</h1>
            <div className="inline_div--dataGrid-wrapper">
                <DataGrid
                    columnAutoWidth={true}
                    dataSource={formattedPostExhibits}
                    onCellPrepared={CustomPreparedHeaderCell}
                    showBorders={true}
                    showRowLines={true}
                    rowAlternationEnabled={true}
                >
                    <FilterRow visible={true} />
                    <KeyboardNavigation enabled={true} />
                    <Paging defaultPageSize={10} />
                    <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={allowedPageSizes}
                    />
                    <SearchPanel
                        visible={true}
                        highlightCaseSensitive={true}
                    />
                    <Toolbar>
                        <Item
                            widget="dxButton"
                            location="after"
                            options={{
                                icon: 'add',
                                text: 'New Post Exhibit',
                                onClick: () => {
                                    navigateToAddPostExhibit();
                                }
                            }}
                        />
                    </Toolbar>
                    <Column
                        dataField="PostExhibitId"
                        caption="PostExhibit ID"
                        cellRender={(cellData) => (
                            <a
                                href="#"
                                onClick={(e) => {
                                    e.preventDefault();
                                    navigateToEditPostExhibit(cellData.value)
                                }}
                            >
                                Edit: {cellData.value}
                            </a>
                        )}
                    />
                    <Column dataField="TaskOrderId" caption="TaskOrder ID" />
                    <Column dataField="VendorName" caption="Vendor" />
                    <Column dataField="ContractId" caption="Contract Number" />
                    <Column dataField="RegionId" caption="Region" />
                    <Column dataField="FiscalYear" caption="Fiscal Year" />
                    <Column dataField="StatusName" caption="Status" />
                    <Column dataField="StartDate" caption="Start Date" />
                    <Column dataField="EndDate" caption="End Date" />
                </DataGrid>
            </div>
        </>
    )
}