import React, { useState, useCallback } from 'react';
import LoadPanel from 'devextreme-react/load-panel';

export const LoadingPanel = (config) => {
    return (
        <LoadPanel
            position={config.location}
            message={config.message}
            showPane={true}
            showIndicator={true}
            visible={true} />
    );
};