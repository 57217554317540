import React, { useEffect, useState, useRef, useCallback, useMemo } from "react";
import { Form, Item as FormItem, GroupItem } from "devextreme-react/form";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, DateBox, NumberBox, SelectBox, TagBox, TextBox, ValidationGroup, Validator } from "devextreme-react";
import GetFetch from "../../hooks/GetFetch";
import { ROUTE_CREATE_EDIT_POST_EXHIBIT } from "../../routes/Routes";
import { BaseCheckBox } from "../../components/BaseCheckBox/BaseCheckBox";
import { FormTextBox } from "../../components/FormTextBox/FormTextBox";
import { ToolbarForm } from "../../components/toolbar-form/toolbar-form";
import IsNullOrEmpty from "../../components/IsNullOrEmpty";
import dayjs from "dayjs";
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isBetween from 'dayjs/plugin/isBetween';
dayjs.extend(isSameOrBefore);
dayjs.extend(isBetween);
import DataGrid, {
    Column,
    Lookup,
    Toolbar,
    Item,
    Editing,
    Popup,
    Form as DataGridForm,
    RequiredRule,
} from 'devextreme-react/data-grid';
import { CustomRule } from "devextreme-react/cjs/validator";
import notify from "devextreme/ui/notify";
import { LoadingIndicatorCircle } from "../../components/Loading";

const URL_regions = '/v1/Region';
const URL_buildings = '/v1/Building/Region/'; //+ Region
const URL_postStatuses = '/v1/PostStatus/GetPostStatus';
const URL_feeTypes = '/v1/FeeType/GetFeeTypes';
const URL_postTypes = '/v1/PostType';
const URL_postLocations = '/v1/PostLocation';
const URL_screeners = '/v1/Screener';
const URL_securityLevels = '/v1/SecurityLevel';
const URL_occupancyAgreementsBySequenceNumber = '/v1/OccupancyAgreement/GetByBuilding'; //+ buildingSequenceNumber

/** @typedef {import('../../../src/types/postExhibit').Post} Post */
/** @typedef {import('../../../src/types/postExhibit').PostAllocation}  PostAllocations */

/** @type {Post} */
const defaultPostFormData = {
    TempPostId: null,
    PostId: null,
    CLIN: null,
    PostLocationId: null,
    PostLocation: null,
    FeeTypeId: null,
    BuildingId: null,
    Building: null,
    BuildingAddress: null,
    PostTypeId: null,
    PostStatusId: null,
    PrimaryPostTypeId: null,
    SecondaryPostTypeId: null,
    ScreenerId: null,
    SecurityLevelId: null,
    SpecialRequirements: '',
    HourlyRate: null,
    StartDate: null,
    EndDate: null,
    StartTime: null,
    EndTime: null,
    HolidayStartTime: null,
    HolidayEndTime: null,
    ReliefBreak: null,
    Utilization: null,
    WeeklySchedule: '',  // comma-separated string of selected days
    HolidaySchedule: '', // comma-separated string of selected holidays    
    AdditionalServiceHours: null,
    TotalServiceHours: null,
    TotalServiceCosts: null,
    FeeType: null,
    PostStatus: null,
    PrimaryPostType: null,
    SecondaryPostType: null,
    Screener: null,
    SecurityLevel: null,
    PostAllocations: []
}

/** @type {PostAllocations} */
const defaultPostAllocation = {
    PostAllocationId: null,
    BuildingNumber: null,
    PostAllocationNumber: null,
    AllocationRate: null,
    OccupancyAgreementId: null,
    OccupancyAgreement: {
        MemberName: null,
        OANumber: null
    }
}

export default function PostForm() {
    /** @type {[Post, React.Dispatch<React.SetStateAction<Post>>]} */
    const [post, setPost] = useState(null);
    /** @type {[Post, React.Dispatch<React.SetStateAction<Post>>]} */
    const [postFormData, setPostFormData] = useState(defaultPostFormData);
    /** @type {[PostAllocations, React.Dispatch<React.SetStateAction<PostAllocations>>]} */
    const [postAllocations, setPostAllocations] = useState([]);
    const [postExhibit, setPostExhibit] = useState(null);
    const [postExhibitFormData, setPostExhibitFormData] = useState(null);
    const [calculatedFields, setCalculatedFields] = useState({
        totalHoursPerDay: 0,
        totalNonHolidays: 0,
        totalHolidayDays: 0,
        totalCalendarDaysWorked: 0,
        totalHours: 0,
        totalHolidayHours: 0
    });

    // State for dropdowns
    const [buildingsData, setBuildingsData] = useState([]);
    const [selectedRegionNumber, setSelectedRegionNumber] = useState(null);
    const [selectedBuildingId, setSelectedBuildingId] = useState(null);
    const [buildingDetails, setBuildingDetails] = useState({
        Name: "",
        Address1: "",
        Address2: "",
        CityName: "",
        State: "",
        ZipCode: ""
    });
    const [dropdownData, setDropdownData] = useState({
        postStatuses: [],
        feeTypes: [],
        postTypes: [],
        screeners: [],
        securityLevels: [],
        regions: [],
        buildings: [],
        postLocations: []
    });

    const [occupancyAgreements, setOccupancyAgreements] = useState([]);
    // UI state
    const [popupVisible, setPopupVisible] = useState(false);
    const [editing, setEditing] = useState(false);
    const [isNewPost, setIsNewPost] = useState(false);
    const [isNewPostExhibit, setIsNewPostExhibit] = useState(false);
    const gridRef = useRef(null);
    const dataRef = useRef(null);
    const [selectedRowIndex, setSelectedRowIndex] = useState(-1);
    const [holidayOptions, setHolidayOptions] = useState(getHolidayOptions());
    const noHolidayWorkedText = "No Holidays Worked";
    const [enableOtherHoliday, setEnableOtherHoliday] = useState(false);
    const [otherHolidayValue, setOtherHolidayValue] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const location = useLocation();
    const minDate = new Date(1900, 0, 1);

    const fetchData = async (url) => {
        const { Message, Success } = await GetFetch(url);
        return Success ? Message : [];
    }

    const loadDropdowns = async () => {
        const results = await Promise.allSettled([
            fetchData(URL_postStatuses),
            fetchData(URL_feeTypes),
            fetchData(URL_postTypes),
            fetchData(URL_screeners),
            fetchData(URL_securityLevels),
            fetchData(URL_regions),
            fetchData(URL_postLocations)
        ]);

        setDropdownData({
            postStatuses: results[0].status === 'fulfilled' ? results[0].value : [],
            feeTypes: results[1].status === 'fulfilled' ? results[1].value : [],
            postTypes: results[2].status === 'fulfilled' ? results[2].value : [],
            screeners: results[3].status === 'fulfilled' ? results[3].value : [],
            securityLevels: results[4].status === 'fulfilled' ? results[4].value : [],
            regions: results[5].status === 'fulfilled' ? results[5].value : [],
            postLocations: results[6].status === 'fulfilled' ? results[6].value : [],

        });
    }

    const initializePostForm = async () => {
        const post = location.state?.post;

        if (post) {
            // Editing existing post
            setIsNewPost(false);
            setPost(post);
            setPostFormData({
                ...post,
                WeeklySchedule: post.WeeklySchedule ?? '',
                HolidaySchedule: post.HolidaySchedule ?? '',
            });
            setPostAllocations(post?.PostAllocations || []);

            // Handle Building and Region
            if (post.Building) {
                if (!selectedRegionNumber && post.Building.RegionNumber) {
                    setSelectedRegionNumber(post.Building.RegionNumber);
                    await fetchAndSetBuildingsForRegion(post.Building.RegionNumber);
                }

                if (post.BuildingId) {
                    setSelectedBuildingId(post.BuildingId);
                    setBuildingDetails({ ...post.BuildingAddress });
                }
            }
        } else {
            // Creating a new post
            setEditing(true);
            setIsNewPost(true);
            setPostFormData(defaultPostFormData);
        }

        setIsNewPostExhibit(location.state?.isNewPostExhibit || false);
        setPostExhibitFormData(location.state?.postExhibitFormData || null);
        setPostExhibit(location.state?.postExhibit || null);
    }

    useEffect(() => {
        const initializeData = async () => {
            setLoading(true);
            await loadDropdowns();
            await initializePostForm();
            setLoading(false);
        };
        initializeData();
    }, []);

    useEffect(() => {
        if (selectedRegionNumber && !selectedBuildingId) {
            fetchAndSetBuildingsForRegion(selectedRegionNumber);
        }
    }, [selectedRegionNumber, selectedBuildingId]);

    // Calculate total hours per day
    useEffect(() => {
        if (postFormData.StartTime && postFormData.EndTime) {
            const start = dayjs(formatTimeForUI(postFormData.StartTime));
            const end = dayjs(formatTimeForUI(postFormData.EndTime));
            if (start.isValid() && end.isValid()) {
                const difference = end.diff(start, 'hour', true);
                setCalculatedFields(prev => ({
                    ...prev,
                    totalHoursPerDay: difference >= 0 ? Number(difference.toFixed(2)) : 0
                }));
            } else {
                console.error("Invalid start or end time format");
            }
        }
    }, [postFormData.StartTime, postFormData.EndTime]);

    // Calculate holiday and non-holiday days
    useEffect(() => {
        const { StartDate, EndDate, WeeklySchedule, HolidaySchedule } = postFormData;

        if ((StartDate && EndDate) && (WeeklySchedule.length > 0 || HolidaySchedule.length > 0)) {
            const scheduleArray = typeof WeeklySchedule === 'string' ? WeeklySchedule.split(',') : WeeklySchedule;
            const holidayArray = typeof HolidaySchedule === 'string' ?
                HolidaySchedule.split(',') : HolidaySchedule;
            const { workdays, holidays } = calculateWorkdays(postFormData.StartDate, postFormData.EndDate, scheduleArray, holidayArray);

            setCalculatedFields(prev => ({
                ...prev,
                totalHolidayDays: holidays,
                totalNonHolidays: workdays,
                totalCalendarDaysWorked: workdays + holidays
            }));
        }
    }, [postFormData.StartDate, postFormData.EndDate, postFormData.WeeklySchedule, postFormData.HolidaySchedule, otherHolidayValue]);

    // Calculate total hours
    useEffect(() => {
        const { totalHoursPerDay, totalNonHolidays, totalHolidayDays } = calculatedFields;
        const holidayHours = calculateTotalHolidayHours(
            formatTimeForUI(postFormData.HolidayStartTime),
            formatTimeForUI(postFormData.HolidayEndTime),
            totalHolidayDays
        );

        const total = (totalHoursPerDay * totalNonHolidays) +
            holidayHours +
            postFormData.AdditionalServiceHours;

        setCalculatedFields(prev => ({
            ...prev,
            totalHours: total,
            totalHolidayHours: holidayHours
        }));
    }, [
        calculatedFields.totalHoursPerDay,
        calculatedFields.totalNonHolidays,
        calculatedFields.totalHolidayDays,
        postFormData.HolidayStartTime,
        postFormData.HolidayEndTime,
        postFormData.AdditionalServiceHours
    ]);

    useEffect(() => {
        const utilRate = calculateUtilizationRate();
        updateField('Utilization', utilRate);
    }, [
        postFormData.FeeTypeId,
        postFormData.PostAllocations
    ]);

    useEffect(() => {
        const loadOccupancyAgreements = async () => {
            if (!IsNullOrEmpty(postFormData?.Building?.SequenceNumber)) {
                try {
                    const url = `${URL_occupancyAgreementsBySequenceNumber}?buildingNumber=${postFormData.Building.SequenceNumber}`;
                    const result = await fetchData(url);
                    setOccupancyAgreements(result);
                } catch (error) {
                    console.error('Error fetching occupancy agreements:', error);
                    setOccupancyAgreements([]);
                }
            } else {
                // Reset when no sequence number
                setOccupancyAgreements([]);
            }
        };

        loadOccupancyAgreements();
    }, [postFormData.BuildingId, postFormData.Building?.SequenceNumber]);

    useEffect(() => {
        const holidays = postFormData?.HolidaySchedule.split(',');
        if (holidays.includes('Other')) {
            setEnableOtherHoliday(true);
        } else {
            setEnableOtherHoliday(false);
            setOtherHolidayValue(null);
        }
    }, [postFormData.HolidaySchedule]);

    const calculateAllocationRate = useCallback((occupancyAgreement) => {
        //AnsiRentSquareFoot / TotalRentSqFt
        if (postFormData?.Building?.AnsiRentSqFt && occupancyAgreement?.totalRentedSqFt) {
            return Number(((occupancyAgreement?.totalRentedSqFt / postFormData?.Building?.AnsiRentSqFt) * 100).toFixed(2))
        }
        return null;

    }, []);

    const calculateUtilizationRate = useCallback(() => {
        if (!postFormData?.FeeTypeId) {
            return null
        }

        // Agency Specific Fee Type (ID: 3)
        if (postFormData.FeeTypeId === 3) {
            return 100;
        }

        // Building Specific Fee Type (ID: 2)
        if (postFormData.FeeTypeId === 2) {
            const tenantCount = postFormData?.PostAllocations?.length || 0;

            if (tenantCount < 2) {
                return 100;
            }

            const totalRentedSqFt = postFormData?.PostAllocations?.reduce(
                (sum, allocation) =>
                    sum + (allocation?.OccupancyAgreement?.TotalRentSqFt || 0),
                0
            );

            const buildingSquareFootage = postFormData?.Building?.AnsiRentSqFt || 0;
            if (buildingSquareFootage === 0) {
                return 0;
            }

            return Number(((totalRentedSqFt / buildingSquareFootage) * 100).toFixed(2));
        }

        return 100;

    }, [postFormData.FeeTypeId]);

    const updateField = useCallback((field, value) => {
        setPostFormData((prevData) => {
            // Handle nested fields like 'Building.Location.Address1'
            if (field.includes('.')) {
                const [parent, ...rest] = field.split('.');
                const nestedField = rest.join('.');

                return {
                    ...prevData,
                    [parent]: {
                        ...prevData[parent],
                        ...(nestedField.includes('.')
                            ? {
                                [nestedField.split('.')[0]]: {
                                    ...prevData[parent][nestedField.split('.')[0]],
                                    [nestedField.split('.')[1]]: value
                                }
                            }
                            : { [nestedField]: value }
                        )
                    }
                };
            }

            // Handle top-level fields
            return { ...prevData, [field]: value };
        });
    }, []);

    const fetchAndSetBuildingsForRegion = async (regionNumber) => {
        const { Message, Success } = await GetFetch(`${URL_buildings}${regionNumber}`);
        if (Success) {
            setBuildingsData(Message);
        } else {
            console.error("Error fetching buildings for the selected region.");
        }
    }

    const handleRegionSelection = useCallback((selectedItem) => {
        const regionNumber = selectedItem.RegionNumber;
        if (selectedItem && regionNumber !== selectedRegionNumber) {
            setSelectedRegionNumber(regionNumber);
            setSelectedBuildingId(null);
            setBuildingDetails({});
            updateField('Building.RegionNumber', regionNumber);
            fetchAndSetBuildingsForRegion(regionNumber);
        }
    }, [selectedRegionNumber, updateField]);

    const handleBuildingSelection = useCallback((selectedItem) => {
        const buildingId = selectedItem.BuildingId;
        if (selectedItem && buildingId !== selectedBuildingId) {
            setSelectedBuildingId(buildingId);
            updateField('BuildingId', buildingId);
            updateField('Building.SequenceNumber', selectedItem.SequenceNumber);
            setBuildingDetails({
                ...selectedItem.Location,
                Name: selectedItem.Name
            });
            updateField('Building.Location', selectedItem.Location);
            updateField('Building.Name', selectedItem.Name);
        }
    }, [selectedBuildingId, updateField]);

    useEffect(() => {
        updateField('BuildingDetails', buildingDetails);
    }, [buildingDetails]);

    const getNthWeekday = (year, month, weekday, n) => {
        const firstDay = dayjs(`${year}-${month.toString().padStart(2, '0')}-01`);
        let current = firstDay.day(weekday);

        if (current.isBefore(firstDay)) {
            current = current.add(7, 'day');
        }

        return current.add((n - 1) * 7, 'day');
    }

    // Holiday rules configuration
    const getHolidayRules = (year) => {
        const rules = {
            "New Year’s Day": dayjs(`${year}-01-01`),
            "Martin Luther King’s Day": getNthWeekday(year, 1, 1, 3), // 3rd Monday of January
            "Washington’s Birthday": getNthWeekday(year, 2, 1, 3), // 3rd Monday of February
            "Memorial Day": dayjs(`${year}-05-31`).day(1).subtract(7, 'day'), // Last Monday of May
            "Juneteenth": dayjs(`${year}-06-19`),
            "Independence Day": dayjs(`${year}-07-04`),
            "Labor Day": getNthWeekday(year, 9, 1, 1), // 1st Monday of September
            "Columbus Day": getNthWeekday(year, 10, 1, 2), // 2nd Monday of October
            "Veterans’ Day": dayjs(`${year}-11-11`),
            "Thanksgiving Day": getNthWeekday(year, 11, 4, 4), // 4th Thursday of November
            "Christmas Day": dayjs(`${year}-12-25`)
        };

        if (!IsNullOrEmpty(otherHolidayValue)) {
            rules["Other"] = dayjs(otherHolidayValue);
        }

        return rules;
    }

    const getHolidayDates = (startDate, endDate, holidaysSelected) => {
        if (!holidaysSelected?.length || holidaysSelected.includes("No Holidays Worked")) {
            return new Set();
        }

        const start = dayjs(startDate);
        const end = dayjs(endDate);
        const startYear = start.year();
        const endYear = end.year();
        const holidayDates = new Set();

        for (let year = startYear; year <= endYear; year++) {
            const holidayRules = getHolidayRules(year);
            holidaysSelected.forEach(holiday => {
                const holidayDate = holidayRules[holiday];
                if (holidayDate?.isBetween(start, end, 'day', '[]')) {
                    holidayDates.add(holidayDate.format('YYYY-MM-DD'));
                }
            });
        }

        return holidayDates;
    }

    const calculateWorkdays = (startDate, endDate, scheduleArray, holidaysSelected) => {
        if (!startDate || !endDate || !scheduleArray?.length) {
            return { workdays: 0, holidays: 0 };
        }

        const start = dayjs(startDate);
        const end = dayjs(endDate);
        let holidayDates = getHolidayDates(startDate, endDate, holidaysSelected);

        let workdays = 0;
        let holidayCount = 0;
        let current = start;

        while (current.isSameOrBefore(end, 'day')) {
            const isScheduledDay = scheduleArray.includes(current.format('dddd'));
            const isHoliday = holidayDates.has(current.format('YYYY-MM-DD'));

            if (isScheduledDay) {
                if (isHoliday) {
                    holidayCount++;
                } else {
                    workdays++;
                }
            }

            current = current.add(1, 'day');
        }

        return {
            workdays,
            holidays: holidayCount
        };
    }

    const calculateTotalHolidayHours = useCallback((holidayStartTime, holidayEndTime, totalHolidayDays) => {
        const startTime = dayjs(holidayStartTime);
        const endTime = dayjs(holidayEndTime);
        const diff = endTime.diff(startTime, 'hour', true);
        return (diff * totalHolidayDays);
    }, [postFormData.HolidayStartTime, postFormData.HolidayEndTime, postFormData.HolidaySchedule]);

    const showMessage = (message, success, displayTime = 7500, width = 500) => {
        notify(
            {
                message: message,
                width: width,
                position: {
                    at: 'top',
                    my: 'top',
                    of: '#postForm_container'
                }
            },
            success ? "success" : 'error',
            displayTime
        )
    }

    const onSaveClick = async ({ validationGroup }) => {
        if (!validationGroup.validate().isValid) {
            showMessage('Please complete and correct all required fields!', false)
            return;
        }

        const isFormChanged = (original, current) => {
            return JSON.stringify(original) !== JSON.stringify({
                ...current,
            });
        }

        if (!isFormChanged(defaultPostFormData, postFormData)) {
            navigate(ROUTE_CREATE_EDIT_POST_EXHIBIT.withSlash, {
                state: {
                    postExhibit: postExhibit,
                    postExhibitFormData: postExhibitFormData,
                    isEditing: true,
                    isNewPostExhibit: isNewPostExhibit
                }
            });

            return;
        }

        let updatedPosts = postExhibitFormData?.Posts ? [...postExhibitFormData.Posts] : [];
        let updatedPostData = {
            ...postFormData,
            PostAllocations: postAllocations?.length ? postAllocations : postFormData.PostAllocations
        };

        const generateUniqueId = () => {
            return `temp_${Date.now()}_${Math.random().toString(36).substring(2, 11)}`;
        };

        //New Post
        if (isNewPost || postFormData.PostId === null) {
            updatedPostData = {
                ...updatedPostData,
                TempPostId: generateUniqueId(),
                PostId: 0
            };
            updatedPosts.push(updatedPostData);
        }

        //Editing an unsaved Post(has TempPostId)
        else if (postFormData.TempPostId !== null) {
            const unsavedPostIndex = updatedPosts.findIndex(
                post => post.TempPostId === postFormData.TempPostId
            );
            if (unsavedPostIndex !== -1) {
                updatedPosts[unsavedPostIndex] = {
                    ...updatedPosts[unsavedPostIndex],
                    ...updatedPostData
                };
            }
        }

        //Editing a saved post (has PostId)
        else if (postFormData.PostId !== 0) {
            const savedPostIndex = updatedPosts.findIndex(
                post => post.PostId === postFormData.PostId
            );
            if (savedPostIndex !== -1) {
                updatedPosts[savedPostIndex] = {
                    ...updatedPosts[savedPostIndex],
                    ...updatedPostData
                };
            }
        }

        const updatedPostExhibitFormData = {
            ...postExhibitFormData,
            Posts: updatedPosts
        };

        navigate(ROUTE_CREATE_EDIT_POST_EXHIBIT.withSlash, {
            state: {
                postExhibit: postExhibit,
                postExhibitFormData: updatedPostExhibitFormData,
                isEditing: true,
                isNewPostExhibit: isNewPostExhibit
            },
            replace: true
        });
    }

    const onBackClick = () => {
        navigate(ROUTE_CREATE_EDIT_POST_EXHIBIT.withSlash, {
            state: {
                postExhibit: postExhibit,
                postExhibitFormData: postExhibitFormData,
                isEditing: true,
                isNewPostExhibit: isNewPostExhibit
            },
            replace: true
        });
    }
    const handleEditClick = () => {
        if (!editing && postFormData) {
            dataRef.current = postFormData;
        } else {
            dataRef.current = undefined;
        }
        setEditing(!editing);
    }

    const onCancelClick = () => {
        setPostFormData(isNewPost ? defaultPostFormData : post);
        handleEditClick();
    }


    const formatDate = (date) => {
        if (!date) return null;
        const formattedDate = new Date(date);
        return formattedDate.toISOString().split('T')[0];
    }

    const formatTime = (time) => {
        if (!time) return null;

        // If already a decimal number between 0-24 with up to 4 decimal places
        if (typeof time === 'number' &&
            time >= 0 &&
            time <= 24 &&
            time.toString().split('.')[1]?.length <= 4) {
            return time;
        }

        const formattedTime = new Date(time);

        // Convert to hours as a decimal (double precision)
        const hours = formattedTime.getHours();
        const minutes = formattedTime.getMinutes();
        const decimalTime = hours + (minutes / 60);

        return parseFloat(decimalTime.toFixed(4));
    }

    const formatTimeForUI = (decimalTime) => {
        if (decimalTime === null || decimalTime === undefined) return null;

        // If already a Date object with only time component (year, month, day all 1970/1/1)
        if (decimalTime instanceof Date &&
            decimalTime.getFullYear() === 1970 &&
            decimalTime.getMonth() === 0 &&
            decimalTime.getDate() === 1) {
            return decimalTime;
        }

        if (typeof decimalTime !== 'number' || decimalTime < 0 || decimalTime >= 24) {
            return null;
        }

        const hours = Math.floor(decimalTime);
        const minutes = Math.round((decimalTime % 1) * 60);

        return new Date(1970, 0, 1, hours, minutes);
    }

    const editRow = useCallback((e) => {
        const intRow = parseInt(e.element.id);
        setSelectedRowIndex(intRow);
        setPopupVisible(true);
        gridRef.current.instance.editRow(intRow);
    }, [gridRef, selectedRowIndex]);

    const buttonRender = useCallback((e) => {
        const oaNumber = e.data.OccupancyAgreement.OANumber;
        setSelectedRowIndex(e.row.rowIndex);
        return <Button id={e.rowIndex} onClick={e => editRow(e)}>{'Edit: ' + oaNumber}</Button>
    }, []);

    const onSavingPopUp = (e) => {
        const { changes } = e;

        if (!changes || changes.length === 0) return;

        const { data, type, key } = changes[0];

        if (!data || IsNullOrEmpty(data.OccupancyAgreement?.OANumber)) return;

        const selectedOption = occupancyAgreements.find(
            oa => oa.OANumber === data.OccupancyAgreement.OANumber
        );

        if (!selectedOption) return;
        const allocationRate = calculateAllocationRate(selectedOption);
        if (type === "insert") {
            // Handle new allocation (no PostAllocationId yet)
            const newAllocation = {
                PostAllocationId: 0,
                PostId: postFormData?.PostId || 0,
                TempPostAllocationId: key.__KEY__, // Use __KEY__ as a temporary identifier
                PostAllocationNumber: data.PostAllocationNumber,
                OccupancyAgreementId: selectedOption.OccupancyAgreementId,
                AllocationRate: allocationRate,
                OccupancyAgreement: {
                    OANumber: selectedOption.OANumber,
                    MemberName: selectedOption.MemberName || "New Member",
                },
            };

            setPostAllocations((prev) => [...prev, newAllocation]);
        } else if (type === 'update') {
            setPostAllocations((prev) =>
                prev.map((allocation) => {
                    if (
                        allocation.PostAllocationId === data.PostAllocationId ||
                        allocation.TempPostAllocationId === key.__KEY__
                    ) {
                        return {
                            ...allocation,
                            PostAllocationNumber: data.PostAllocationNumber,
                            OccupancyAgreementId: selectedOption.OccupancyAgreementId,
                            OccupancyAgreement: {
                                AllocationRate: allocationRate,
                                ...allocation.OccupancyAgreement,
                                OANumber: selectedOption.OANumber,
                                MemberName: selectedOption.MemberName || allocation.OccupancyAgreement?.MemberName,
                            },
                        };
                    }
                    return allocation;
                })
            );
        }

        setPopupVisible(false);
    }

    const updateOptions = (selectedHolidays) => {
        const isNoHolidaysSelected = selectedHolidays.includes(noHolidayWorkedText);
        const updatedOptions = getHolidayOptions().map(option => ({
            ...option,
            disabled: isNoHolidaysSelected && option.text !== noHolidayWorkedText,
        }));
        setHolidayOptions(updatedOptions);
    }

    return (
        <div id="postForm_container">
            <h1>Post Details</h1>
            <ValidationGroup>
                <ToolbarForm
                    toggleEditing={() => setEditing(!editing)}
                    onSaveClick={onSaveClick}
                    editing={editing}
                    onBackClick={onBackClick}
                    onCancelClick={onCancelClick}
                />
                {loading && <LoadingIndicatorCircle message='Loading Post Details' />}
                {!loading &&
                    <Form>
                        <GroupItem caption="General Post Details" colCount={4}>
                            <FormItem>
                                <SelectBox
                                    label={"Region"}
                                    dataSource={dropdownData.regions}
                                    displayExpr={"RegionNumber"}
                                    valueExpr={"RegionNumber"}
                                    value={selectedRegionNumber}
                                    readOnly={!editing}
                                    onSelectionChanged={(e) => handleRegionSelection(e.selectedItem)}
                                    searchEnabled={true}
                                />
                            </FormItem>
                            <FormItem>
                                <SelectBox
                                    label={"Building Number"}
                                    dataSource={buildingsData}
                                    displayExpr={"SequenceNumber"}
                                    valueExpr={"BuildingId"}
                                    value={selectedBuildingId}
                                    readOnly={!editing}
                                    onSelectionChanged={(e) => handleBuildingSelection(e.selectedItem)}
                                    searchEnabled={true}
                                    disabled={!selectedRegionNumber}
                                />
                            </FormItem>
                            <FormItem >
                                <FormTextBox
                                    label={'Building Name'}
                                    value={buildingDetails.Name || ""}
                                    isEditing={false}
                                />
                            </FormItem>
                            <FormItem>
                                <FormTextBox
                                    label={'Address1'}
                                    value={buildingDetails.Address1 || ""}
                                    isEditing={false}
                                />
                            </FormItem>
                            <FormItem>
                                <FormTextBox
                                    label={'Address2'}
                                    value={buildingDetails.Address2 || ""}
                                    isEditing={false}
                                />
                            </FormItem>
                            <FormItem>
                                <FormTextBox
                                    label={"City Name"}
                                    value={buildingDetails.CityName || ""}
                                    readOnly={true}
                                    isEditing={false}
                                />
                            </FormItem>
                            <FormItem>
                                <FormTextBox
                                    label={"State"}
                                    value={buildingDetails.State || ""}
                                    isEditing={false}
                                />
                            </FormItem>
                            <FormItem>
                                <FormTextBox
                                    label={"ZIP"}
                                    value={buildingDetails.ZipCode || ""}
                                    isEditing={false}
                                />
                            </FormItem>
                            <FormItem >
                                <TextBox
                                    label={"Post Number"}
                                    value={postFormData?.PostNumber}
                                    onValueChange={e => updateField('PostNumber', e)}
                                    readOnly={!editing}
                                />
                            </FormItem>
                            <FormItem>
                                <SelectBox
                                    inputAttribute={{ "aria-label": "Post Location" }}
                                    dataSource={dropdownData.postLocations}
                                    searchEnabled={true}
                                    label={"Post Location"}
                                    onSelectionChanged={({ selectedItem }) => { updateField('PostLocationId', selectedItem.PostLocationId) }}
                                    value={postFormData?.PostLocationId}
                                    displayExpr="Name"
                                    valueExpr="PostLocationId"
                                    readOnly={!editing}
                                >
                                    <Validator>
                                        <RequiredRule message="Post Location is required" />
                                    </Validator>
                                </SelectBox>
                            </FormItem>
                            <FormItem >
                                <TextBox
                                    label={"CLIN"}
                                    value={postFormData?.CLIN}
                                    onValueChange={e => updateField('CLIN', e)}
                                    readOnly={!editing}
                                    placeholder="~1234AB"
                                />
                            </FormItem>
                            <FormItem>
                                <SelectBox
                                    label={"Post Status"}
                                    dataSource={dropdownData.postStatuses}
                                    displayExpr={"Name"}
                                    valueExpr={"PostStatusId"}
                                    value={postFormData?.PostStatusId}
                                    readOnly={!editing}
                                    onSelectionChanged={({ selectedItem }) => {
                                        updateField('PostStatusId', selectedItem.PostStatusId);
                                        updateField('PostStatus', selectedItem);
                                    }}
                                    searchEnabled={true}
                                >
                                    <Validator>
                                        <RequiredRule message="Post Status is required" />
                                    </Validator>
                                </SelectBox>
                            </FormItem>
                            <FormItem>
                                <SelectBox
                                    inputAttribute={{ "aria-label": "Fee Type" }}
                                    dataSource={dropdownData.feeTypes}
                                    searchEnabled={true}
                                    label={"Fee Type"}
                                    onSelectionChanged={({ selectedItem }) => {
                                        updateField('FeeTypeId', selectedItem.FeeTypeId);
                                        updateField('FeeType', selectedItem)
                                    }}
                                    value={postFormData?.FeeTypeId}
                                    displayExpr="FeeTypeName"
                                    valueExpr="FeeTypeId"
                                    readOnly={!editing}
                                >
                                    <Validator>
                                        <RequiredRule message="Fee Type is required" />
                                    </Validator>
                                </SelectBox>
                            </FormItem>
                            <FormItem>
                                <SelectBox
                                    inputAttribute={{ "aria-label": "Primary Post Type" }}
                                    dataSource={dropdownData.postTypes}
                                    searchEnabled={true}
                                    label={"Primary Post Type"}
                                    onSelectionChanged={({ selectedItem }) => { updateField('PrimaryPostTypeId', selectedItem.PostTypeId) }}
                                    value={postFormData?.PrimaryPostTypeId}
                                    displayExpr="Name"
                                    valueExpr="PostTypeId"
                                    readOnly={!editing}
                                >
                                    <Validator>
                                        <RequiredRule message="Primary Post Type is required" />
                                    </Validator>
                                </SelectBox>
                            </FormItem>
                            <FormItem>
                                <SelectBox
                                    inputAttribute={{ "aria-label": "Secondary Post Type" }}
                                    dataSource={dropdownData.postTypes}
                                    searchEnabled={true}
                                    label={"Secondary Post Type"}
                                    onSelectionChanged={({ selectedItem }) => updateField('SecondaryPostTypeId', selectedItem.PostTypeId)}
                                    value={postFormData?.SecondaryPostTypeId}
                                    displayExpr="Name"
                                    valueExpr="PostTypeId"
                                    readOnly={!editing}
                                />
                            </FormItem>
                            <FormItem>
                                <SelectBox
                                    inputAttribute={{ "aria-label": "Screener" }}
                                    dataSource={dropdownData.screeners}
                                    searchEnabled={true}
                                    label={"Screeners"}
                                    onSelectionChanged={({ selectedItem }) => updateField('ScreenerId', selectedItem.ScreenerId)}
                                    value={postFormData?.ScreenerId}
                                    displayExpr="Name"
                                    valueExpr="ScreenerId"
                                    readOnly={!editing}
                                >
                                    <Validator>
                                        <RequiredRule message="Screener is required" />
                                    </Validator>
                                </SelectBox>
                            </FormItem>
                            <FormItem>
                                <SelectBox
                                    inputAttribute={{ "aria-label": "Fitness/Security level" }}
                                    dataSource={dropdownData.securityLevels}
                                    searchEnabled={true}
                                    label={"Fitness/Security Level"}
                                    onSelectionChanged={({ selectedItem }) => updateField('SecurityLevelId', selectedItem.SecurityLevelId)}
                                    value={postFormData?.SecurityLevelId}
                                    displayExpr="Name"
                                    valueExpr="SecurityLevelId"
                                    readOnly={!editing}
                                >
                                    <Validator>
                                        <RequiredRule message="Fitness/Security is required" />
                                    </Validator>
                                </SelectBox>
                            </FormItem>
                            <FormItem>
                                <BaseCheckBox
                                    elementAttr={{ "aria-label": "Relief On Break" }}
                                    onCheckedValueChanged={({ value }) => updateField('ReliefBreak', value)}
                                    text="Relief On Break"
                                    value={postFormData?.ReliefBreak ?? false}
                                    name="ReliefBreak"
                                    readOnly={!editing}

                                />
                            </FormItem>
                            <FormItem>
                                <NumberBox
                                    inputAttribute={{ "aria-label": "Total Number of PTS Tablets Required" }}
                                    format={"#,###"}
                                    label="Total Number of PTS Tablets Required"
                                    onValueChanged={e => updateField('RequiredTablets', e.value)}
                                    value={postFormData?.RequiredTablets}
                                    readOnly={!editing}
                                />
                            </FormItem>
                            <FormItem>
                                <TextBox
                                    label={"Special Requirements"}
                                    maxLength={500}
                                    onValueChange={e => updateField('SpecialRequirements', e)}
                                    value={postFormData?.SpecialRequirements}
                                    readOnly={!editing}
                                >
                                    <Validator>
                                        <CustomRule
                                            message="The limit is 500 character!"
                                            validationCallback={(e) => {
                                                if (e?.value?.length > 500) {
                                                    return false;
                                                }
                                                return true;
                                            }}
                                        />
                                    </Validator>
                                </TextBox>
                            </FormItem>
                            <FormItem>
                                <NumberBox
                                    inputAttribute={{ "aria-label": "PSO Hourly Rate" }}
                                    format={"$ #,##0.##"}
                                    label="PSO Post Hourly Rate"
                                    onValueChanged={e => updateField('HourlyRate', e.value)}
                                    value={postFormData?.HourlyRate}
                                    readOnly={!editing}
                                />
                            </FormItem>
                            <FormItem >
                                <DateBox
                                    type="datetime"
                                    useMaskBehavior={true}
                                    inputAttr={{ "aria-label": "Start Date" }}
                                    label="Start Date"
                                    min={minDate}
                                    value={postFormData?.StartDate}
                                    showDropDownButton={true}
                                    onValueChanged={e => {
                                        const formattedDate = formatDate(e.value)
                                        updateField('StartDate', formattedDate)
                                    }}
                                    displayFormat={"MM/dd/yyyy"}
                                    placeholder='MM/dd/yyyy'
                                    readOnly={!editing}
                                    validationMessage="Please enter a valid start date."
                                >
                                    <Validator>
                                        <RequiredRule message="Start Date is required" />
                                    </Validator>
                                </DateBox>
                            </FormItem>
                            <FormItem >
                                <DateBox
                                    type="datetime"
                                    useMaskBehavior={true}
                                    inputAttr={{ "aria-label": "End Date" }}
                                    label="End Date"
                                    min={minDate}
                                    value={postFormData?.EndDate}
                                    showDropDownButton={true}
                                    onValueChanged={e => {
                                        const formattedDate = formatDate(e.value)
                                        updateField('EndDate', formattedDate)
                                    }}
                                    displayFormat={"MM/dd/yyyy"}
                                    placeholder={"MM/dd/yyyy"}
                                    readOnly={!editing}
                                >
                                    <Validator>
                                        <RequiredRule message="End Date is required" />
                                        <CustomRule
                                            message="End Date must be after Start Date"
                                            validationCallback={() => {
                                                if (!postFormData?.StartDate || !postFormData?.EndDate) {
                                                    return true;
                                                }
                                                return postFormData.EndDate > postFormData.StartDate;
                                            }}
                                        />
                                    </Validator>
                                </DateBox>
                            </FormItem>
                            <FormItem >
                                <DateBox
                                    type="time"
                                    inputAttr={{ "aria-label": "Start Time" }}
                                    label="Start Time"
                                    value={formatTimeForUI(postFormData?.StartTime)}
                                    onValueChanged={e => {
                                        const formattedTime = formatTime(e.value)
                                        updateField('StartTime', formattedTime)
                                    }}
                                    placeholder="~9:00 AM"
                                    readOnly={!editing}
                                >
                                    <Validator>
                                        <RequiredRule message="Start Time is required" />
                                    </Validator>
                                </DateBox>
                            </FormItem>
                            <FormItem>
                                <DateBox
                                    type="time"
                                    inputAttr={{ "aria-label": "End Time" }}
                                    label="End Time"
                                    value={formatTimeForUI(postFormData?.EndTime)}
                                    onValueChanged={e => {
                                        const formattedTime = formatTime(e.value)
                                        updateField('EndTime', formattedTime)
                                    }}
                                    validationMessage="Please enter a valid end time."
                                    placeholder="~5:00 PM"
                                    readOnly={!editing}
                                >
                                    <Validator>
                                        <RequiredRule message="End Time is required" />
                                        <CustomRule
                                            message="End Time must be after Start Time"
                                            validationCallback={() => {
                                                if (!postFormData?.EndTime || !postFormData?.StartTime) {
                                                    return true;
                                                }
                                                return postFormData.EndTime > postFormData.StartTime;
                                            }}
                                        />
                                    </Validator>
                                </DateBox>
                            </FormItem>
                            <FormItem>
                                <FormTextBox
                                    label={"Total Hours Per Day"}
                                    value={calculatedFields?.totalHoursPerDay}
                                    readonly={true}
                                />
                            </FormItem>
                            <FormItem>
                                <TagBox
                                    inputAttr={{ "aria-label": "Schedule" }}
                                    label="Schedule"
                                    items={getWeekDaysOptions()}
                                    displayExpr="text"
                                    valueExpr="text"
                                    showSelectionControls={true}
                                    value={postFormData?.WeeklySchedule.split(',')}
                                    defaultValue={postFormData.WeeklySchedule ? postFormData.WeeklySchedule.split(',') : []}
                                    onValueChanged={(e) => {
                                        const filteredSelectedDays = e.value.filter(day => day !== '');
                                        const selectedDays = filteredSelectedDays.join(',');
                                        if (selectedDays !== postFormData.WeeklySchedule) {
                                            updateField('WeeklySchedule', selectedDays);
                                        }
                                    }}
                                />
                            </FormItem>
                            <FormItem>
                                <FormTextBox
                                    label={"Total Non-Holidays"}
                                    value={calculatedFields?.totalNonHolidays}
                                    isValid={true}
                                    readonly={true}
                                />
                            </FormItem>
                            <FormItem>
                                <TagBox
                                    inputAttr={{ "aria-label": "Holiday Work Schedule" }}
                                    label="Holiday Work Schedule"
                                    items={holidayOptions}
                                    displayExpr="text"
                                    valueExpr="text"
                                    showSelectionControls={true}
                                    value={postFormData?.HolidaySchedule.split(',')}
                                    defaultValue={postFormData.HolidaySchedule ? postFormData.HolidaySchedule.split(',') : []}
                                    onValueChanged={(e) => {
                                        const filterEmptyValues = (days) => days.filter(day => day !== '');
                                        const handleNoHolidaySelected = (days) => {
                                            if (days.includes(noHolidayWorkedText)) {
                                                if (days.length === 13) {
                                                    return days.filter(day => day !== noHolidayWorkedText);
                                                }
                                                return [noHolidayWorkedText];
                                            }
                                            return days.filter(day => day !== noHolidayWorkedText);
                                        };

                                        let selectedDays = filterEmptyValues(e.value);
                                        selectedDays = handleNoHolidaySelected(selectedDays);
                                        const updatedSelectedDays = selectedDays.join(',');
                                        if (updatedSelectedDays !== postFormData.HolidaySchedule) {
                                            updateField('HolidaySchedule', updatedSelectedDays);
                                            updateOptions(selectedDays);
                                        }
                                    }}
                                />
                            </FormItem>
                            <FormItem >
                                <DateBox
                                    type="datetime"
                                    useMaskBehavior={true}
                                    inputAttr={{ "aria-label": "Other Holiday Date" }}
                                    label="Other Holiday Date"
                                    min={minDate}
                                    value={otherHolidayValue}
                                    showDropDownButton={true}
                                    displayFormat={"MM/dd/yyyy"}
                                    placeholder={"MM/dd/yyyy"}
                                    readOnly={!enableOtherHoliday}
                                    onValueChanged={e => {
                                        setOtherHolidayValue(e.value);
                                    }}
                                >
                                    <Validator>
                                        <CustomRule
                                            message="Other Holiday Date is required when 'Other' is selected."
                                            validationCallback={() => {
                                                if (!enableOtherHoliday) {
                                                    return true;
                                                }
                                                return !!otherHolidayValue;
                                            }}
                                        />
                                        <CustomRule
                                            message="Other Holiday Date must be within the Start and End Dates."
                                            validationCallback={() => {
                                                if (!enableOtherHoliday || !otherHolidayValue) {
                                                    return true;
                                                }

                                                const startDate = new Date(postFormData?.StartDate);
                                                const endDate = new Date(postFormData?.EndDate);

                                                return (
                                                    otherHolidayValue >= startDate &&
                                                    otherHolidayValue <= endDate
                                                );
                                            }}
                                        />
                                    </Validator>
                                </DateBox>
                            </FormItem>
                            <FormItem>
                                <DateBox
                                    type='time'
                                    inputAttr={{ "aria-label": "Holiday Work Start Time" }}
                                    label="Holiday Work Start Time"
                                    value={formatTimeForUI(postFormData?.HolidayStartTime)}
                                    onValueChanged={e => {
                                        const formattedTime = formatTime(e.value)
                                        updateField('HolidayStartTime', formattedTime)
                                    }}
                                    placeholder='~8:00 AM'
                                    readOnly={!editing}
                                >
                                    <Validator>
                                        <RequiredRule message="Holiday Start Time is required" />
                                    </Validator>
                                </DateBox>
                            </FormItem>
                            <FormItem>
                                <DateBox
                                    type='time'
                                    inputAttribute={{ "aria-label": "Holiday Work End Time" }}
                                    label="Holiday Work End Time"
                                    value={formatTimeForUI(postFormData?.HolidayEndTime)}
                                    onValueChanged={(e) => {
                                        const formattedTime = formatTime(e.value)
                                        updateField('HolidayEndTime', formattedTime)
                                    }}
                                    placeholder='~5:00 PM'
                                    readOnly={!editing}
                                >
                                    <Validator>
                                        <RequiredRule message="Holiday End Time is required" />
                                        <CustomRule
                                            message="Holiday End Time must be after Holiday Start Time"
                                            validationCallback={() => {
                                                if (!postFormData?.HolidayEndTime || !postFormData?.HolidayStartTime) {
                                                    return true;
                                                }
                                                return postFormData.HolidayEndTime > postFormData.HolidayStartTime;
                                            }}
                                        />
                                    </Validator>
                                </DateBox>
                            </FormItem>
                            <FormItem>
                                <FormTextBox
                                    inputAttribute={{ "aria-label": "Total Holiday Days" }}
                                    label={"Total Holiday Days"}
                                    value={calculatedFields.totalHolidayDays}
                                    readOnly={true}
                                />
                            </FormItem>
                            <FormItem>
                                <FormTextBox
                                    inputAttribute={{ "aria-label": "Total Calendar Days Worked" }}
                                    label="Total Calendar Days Worked"
                                    value={calculatedFields.totalCalendarDaysWorked}
                                    readOnly={true}
                                />
                            </FormItem>
                            <FormItem>
                                <NumberBox
                                    inputAttribute={{ "aria-label": "Temporary Additional Service Hours" }}
                                    label="Temporary Additional Service Hours"
                                    value={postFormData?.AdditionalServiceHours}
                                    onValueChanged={(e) => updateField('AdditionalServiceHours', e.value)}
                                    readOnly={!editing}
                                    format="#0.##"
                                    useMaskBehavior={true}
                                >
                                    <Validator>
                                        <CustomRule
                                            message="Value must be less than 6 digits and up to 2 decimals."
                                            validationCallback={(e) => {
                                                const value = e.value;

                                                if (value == null || isNaN(value)) return false;

                                                const [integerPart, decimalPart] = value.toString().split('.');
                                                if (integerPart?.length > 5) return false;
                                                if (decimalPart?.length > 2) return false;

                                                return true;
                                            }}
                                        />
                                    </Validator>
                                </NumberBox>
                            </FormItem>
                            <FormItem>
                                <FormTextBox
                                    inputAttribute={{ "aria-label": "Total Hours" }}
                                    label="Total Hours"
                                    value={isNaN(calculatedFields.totalHours) ? null : calculatedFields.totalHours}
                                    readOnly={true}
                                />
                            </FormItem>
                            <FormItem>
                                <FormTextBox
                                    label="Total Holiday Hours"
                                    value={isNaN(calculatedFields.totalHolidayHours) ? null : calculatedFields.totalHolidayHours}
                                    readOnly={true}
                                />
                            </FormItem>
                            <FormItem>
                                <FormTextBox
                                    label="Utilization Rate"
                                    value={postFormData?.Utilization}
                                    readOnly={true}
                                    format="#.##'%'"
                                />
                            </FormItem>
                            <FormItem>
                                <NumberBox
                                    label="Inflation Factor"
                                    inputAttribute={{ "aria-label": "Inflation Factor" }}
                                    value={postFormData?.InflationFactor}
                                    readOnly={!editing}
                                    format="#00.00'%'"
                                    min={0}
                                    max={100}
                                    onValueChanged={e => updateField('InflationFactor', e.value)}
                                />
                            </FormItem>
                        </GroupItem>
                        <GroupItem caption="Post Allocations">
                            <DataGrid
                                id='postExhibit_postAllocationsDataGrid'
                                dataSource={postAllocations}
                                showBorders={true}
                                columnAutoWidth={true}
                                ref={gridRef}
                                key="PostAllocationId"
                                onSaving={onSavingPopUp}
                            >
                                <Toolbar visible={editing} >
                                    <Item name="addRowButton" showText="always" caption="Add Post Allocation" />
                                </Toolbar>
                                <Editing mode="popup" allowUpdating={true} allowAdding={true} allowDeleting={true} >
                                    <Popup
                                        showTitle={true}
                                        title="Editing A Post Allocation"
                                        visible={popupVisible}
                                        Editing={popupVisible}
                                    />
                                    <DataGridForm
                                        labelLocation="top" colCount='auto'>
                                        <Item caption='Select Post Allocation' itemType='group' colCount='auto' >
                                            <Item itemType='group'>
                                                <Item dataField='OccupancyAgreement.OANumber' caption='OA Number' labelMode='static' />
                                            </Item>
                                            <Item itemType='group'>
                                                <Item dataField='PostAllocationNumber' caption='Post Allocation Number' labelMode='static' />
                                            </Item>
                                            <Item dataField='PostAllocationId' visible={false} />
                                            <Item dataField='TempPostAllocationId' visible={false} />
                                        </Item>
                                    </DataGridForm>
                                </Editing>
                                <Column type='buttons' visible={editing} cellRender={buttonRender}>
                                    <Button
                                        name='edit'
                                        elementAttr={{ "aria-label": "Billing Date" }}
                                    />
                                </Column>
                                <Column dataField="PostAllocationId" caption="Post Allocation Id" allowEditing={false} />
                                <Column dataField="PostAllocationNumber" caption="Post Allocation Number" >
                                    <RequiredRule message="Post Allocation Number is required" />
                                </Column>
                                <Column dataField="OccupancyAgreement.OANumber" caption="OA Number" >
                                    <Lookup
                                        dataSource={occupancyAgreements}
                                        displayExpr='OANumber'
                                        valueExpr='OANumber'
                                    />
                                    <RequiredRule message="Occupancy Agreement is required" />
                                </Column>
                                <Column dataField="CustomerName" caption="Customer Name" allowEditing={false} />
                                <Column dataField="CustomerNumber" caption="Customer Number" allowEditing={false} />
                                {/* <Column dataField="AllocationRate" caption="Allocation Rate" allowEditing={false} /> */}
                                <Column
                                    caption="Total Hours By customer Agency"
                                    allowEditing={false}
                                    calculateCellValue={(rowData) => {
                                        const totalHours = calculatedFields.totalHours || 0;
                                        if (postFormData?.FeeTypeId !== 2) {
                                            return totalHours;
                                        }
                                        if (postFormData?.PostAllocations.length < 2) {
                                            return totalHours;
                                        }

                                        const occupancyAgreement = occupancyAgreements.find(
                                            oa => oa.OANumber === rowData.OccupancyAgreement.OANumber
                                        );

                                        const allocationRate = calculateAllocationRate(occupancyAgreement) || 0;
                                        const utilizationRate = postFormData?.Utilization || 0;

                                        const totalHoursByAgency = totalHours * (utilizationRate / 100) * (allocationRate / 100);
                                        return totalHoursByAgency.toFixed(2);
                                    }}
                                />
                            </DataGrid>
                        </GroupItem>
                    </Form>
                }
            </ValidationGroup>
        </div>
    );
}

// ================== HELPER FUNCTIONS ============================ //

function getWeekDaysOptions() {
    return [
        { id: 1, text: 'Monday' },
        { id: 2, text: 'Tuesday' },
        { id: 3, text: 'Wednesday' },
        { id: 4, text: 'Thursday' },
        { id: 5, text: 'Friday' },
        { id: 6, text: 'Saturday' },
        { id: 7, text: 'Sunday' }
    ]
}

function getHolidayOptions() {
    return [
        { id: 1, text: "New Year’s Day" },
        { id: 2, text: "Martin Luther King’s Day" },
        { id: 3, text: "Washington’s Birthday" },
        { id: 4, text: "Memorial Day" },
        { id: 5, text: "Juneteenth" },
        { id: 6, text: "Independence Day" },
        { id: 7, text: "Labor Day" },
        { id: 8, text: "Columbus Day" },
        { id: 9, text: "Veterans’ Day" },
        { id: 10, text: "Thanksgiving Day" },
        { id: 11, text: "Christmas Day" },
        { id: 12, text: "No Holidays Worked" },
        { id: 13, text: "Other" }
    ]
}