const PersonnelTypeIds = {
  1: {
    shortName: "CO",
    longName: "Contract Officer",
  },
  2: {
    shortName: "COR",
    longName: "Contract Officer Representative",
  },
  3: {
    shortName: "ACOR",
    longName: "Alternate Contract Officer Representative",
  },
  4: {
    shortName: "CS",
    longName: "CS",
  },
  5: {
    shortName: "Budget Personnel",
    longName: "Budget Personnel",
  },

  /**
   * Get the short name for a given PersonnelTypeId.
   * @param {number} id - The PersonnelTypeId.
   * @returns {string} The short name, or "Unknown" if the ID is not found.
   */
  getShortNameById(id) {
    return this[id]?.shortName || "Unknown";
  },

  /**
   * Get the long name for a given PersonnelTypeId.
   * @param {number} id - The PersonnelTypeId.
   * @returns {string} The long name, or "Unknown" if the ID is not found.
   */
  getLongNameById(id) {
    return this[id]?.longName || "Unknown";
  },
};

export default PersonnelTypeIds;
