import { useState } from 'react';
import { 
    ROUTE_CRMA_HISTORY_GENERATED_BILL_BFC, 
    ROUTE_CRMA_HISTORY_GENERATED_BILL_GSA, 
    ROUTE_CRMA_HISTORY_GENERATED_BILL_REGION 
} from '../routes/Routes';
import GetFetch from '../hooks/GetFetch';
import PutPostPatchFetch from '../hooks/PutPostPatchFetch';
import DataGrid, {
    Column,
    Editing,
    Form,
    FormItem,
    KeyboardNavigation,
    Lookup,
    Popup as DataGrid_Popup,
    Pager,
    Paging,
    SearchPanel,
    Toolbar
} from 'devextreme-react/data-grid';
import { LoadingIndicatorCircle } from '../components/Loading';
import { Toast } from 'devextreme-react';
import { Item } from 'devextreme-react/form';
import { Popup, Position } from 'devextreme-react/popup';
import CustomPreparedHeaderCell from '../components/CustomPreparedHeaderCell';
import { BaseButton } from '../components/BaseButton/BaseButton';
import { BaseDateField } from '../components/BaseDateField/BaseDateField';
import { BaseSelectField } from '../components/BaseSelectField/BaseSelectField';
import { allowedPageSizes } from '../components/GlobalDataGridConfigurations';
import './CRMA_HistoryBillGeneration.scss';


const CRMA_HistoryBillGeneration = ({ route }) => {
    const billStatusData = [
        {
            param: "All",
            displayValue: "All"
        },
        {
            param: "Generated",
            displayValue: "Generated"
        },
        {
            param: "2ndApprovalNeeded",
            displayValue: "2nd Approval Needed"
        },
        {
            param: "Rejected",
            displayValue: "Rejected"
        },
        {
            param: "Approved",
            displayValue: "Approved"
        },
        {
            param: "Duplicates",
            displayValue: "Duplicate OA Numbers"
        }
    ];

    const [dataSource, setDataSource] = useState();
    const [billingDateParam, setBillingDateParam] = useState();
    const [billingDateValue, setBillingDateValue] = useState();
    const [statusHistory, setStatusHistory] = useState();
    const [billStatusName, setBillStatusName] = useState(billStatusData[0].param);

    const [enablePopup, setEnableVisible] = useState(false);
    const [billingDataLoadingIndicator, setBillingDataLoadingIndicator] = useState(false);
    const [errorAlertVisible, setErrorAlertVisible] = useState(false);
    const [successAlertVisible, setSuccessAlertVisible] = useState(false);
    const [errorMessage, setErrorMessage] = useState();
    

    const getRouteId = (route) => {
        switch (route) {
            case 0:
                return {
                    Title: ROUTE_CRMA_HISTORY_GENERATED_BILL_BFC.title,
                    BatchApprove: `/v1/CRMAGeneratedBillBfc/ChangeRowStatus/Approve/`,
                    BatchReject: `/v1/CRMAGeneratedBillBfc/ChangeRowStatus/Reject/`,
                    Read: `/v1/CRMAGeneratedBillBfc/GetCRMAGeneratedBillBfc/${billingDateParam}/0/20000/${billStatusName}`,
                    Update: `/v1/CRMAGeneratedBillBfc/UpdateCRMAGeneratedBillBfc`
                };
            case 1:
                return {
                    Title: ROUTE_CRMA_HISTORY_GENERATED_BILL_GSA.title,
                    BatchApprove: `/v1/CRMAGeneratedBill/ChangeRowStatus/Approve/`,
                    BatchReject: `/v1/CRMAGeneratedBill/ChangeRowStatus/Reject/`,
                    Read: `/v1/CRMAGeneratedBill/GetCRMAGeneratedBill/${billingDateParam}/0/20000/${billStatusName}`,
                    Update: `/v1/CRMAGeneratedBill/UpdateCRMAGeneratedBill`
                };
            case 2: 
                return {
                    Title: ROUTE_CRMA_HISTORY_GENERATED_BILL_REGION.title,
                    BatchApprove: `/v1/CRMAGeneratedBillRegion/ChangeRowStatus/Approve/`,
                    BatchReject: `/v1/CRMAGeneratedBillRegion/ChangeRowStatus/Reject/`,
                    Read: `/v1/CRMAGeneratedBillRegion/GetCRMAGeneratedBillRegion/${billingDateParam}/0/20000/${billStatusName}`,
                    Update: `/v1/CRMAGeneratedBillRegion/UpdateCRMAGeneratedBillRegion`
                };
        };
    };

    const { 
        BatchApprove,
        BatchReject,
        Read,
        Title, 
        Update 
    } = getRouteId(route);

    const getData = async () => {
        try {
            setBillingDataLoadingIndicator(true);
            const data = await GetFetch(Read);
            const { Message, Success, Errors } = data;
            console.log("Message: ", Message);
            if (Success) { 
                const res = Message.map(item => 
                    Object.fromEntries(
                        Object.entries(item).map(([key, value]) =>
                            [key, value]
                        )
                    )
                );
                setDataSource(res);
                
                const status = res.map(({ StepStatusHistory }) => {
                    return StepStatusHistory[StepStatusHistory.length - 1];
                });
                setStatusHistory(status);
            };

            if (Errors.length > 0) console.error("Errors: ", Errors[0]);
            console.log("Get data: ", Message, Success)
        } catch (errors) {
            setBillingDataLoadingIndicator(false);
            console.error(errors)
        } finally {
            setBillingDataLoadingIndicator(false);
        }
    };

    /**
     *  @todo 
     * Consolidate functions setApproveAllStatus() and setRejectAllStatus(); 
     * listen for the innerText of the button label to determine whether to 
     * BatchApprove or BatchReject. 
     */

    const setApproveAllStatus = async () => {
        const { Errors, Message, Success } = await PutPostPatchFetch(BatchApprove + billingDateParam, "PUT", [0]);
        /**
         * @todo: Both Success and Errors[0] need Toast components for gracefully 
         * notifying the user of the end result of the request.
         */
        if (Success) console.log("Message: ", Message);
        if (Errors[0]) console.error(Errors[0]);
    };

    const setRejectAllStatus = async () => {
        const { Errors, Message, Success } = await PutPostPatchFetch(BatchReject + billingDateParam, "PUT", [0]);
        /**
         * @todo: Both Success and Errors[0] need Toast components for gracefully 
         * notifying the user of the end result of the request.
         */
        if (Success) console.log("Message: ", Message);
        if (Errors[0]) console.error(Errors[0]);
    };

    const stringifyDate = (value) => {
        const [month, , year] = value.split('/');
        return `${month}/01/${year}`;
    };

    const handleDateValueChange = ({ value }) => {
        const formattedDate = stringifyDate(value);

        setBillingDateParam(encodeURIComponent(formattedDate));
        setBillingDateValue(formattedDate); 
    };

    const onRowUpdating = async (e) => {
        const previousData = e.oldData;
        const updatedData = e.newData;
        let updatedRow = { ...previousData, ...updatedData };
        updatedRow.Id = 0;
        updatedRow.BillDate = updatedRow.BillDate.slice(0, 10);

        try {
            const { Message, Success, Errors } = await PutPostPatchFetch(Update, 'PUT', [updatedRow]);
            /**
             * @todo: Success needs Toast component for gracefully 
             * notifying the user of the end result of the request.
             */
            if (Success) setSuccessAlertVisible(true);

            if (Errors) {
                setErrorAlertVisible(true);
                setErrorMessage(Errors[0].Message)
                console.error("Error on editing", Errors[0]);
            };

        } catch (error) {
            console.error(error);
        }
    };

    const onUpdatedBillStatus = (e) => {
        const { param } = e.selectedItem;
        setBillStatusName(param)
    };

    /**
     * @todo: Use onEditorPreparing() to set the select option  
     * `2nd Approval Needed` to read-only
     */
    // const onEditorPreparing = (e) => {
    //     console.log("e: ", e);
    //     if (e.parentType === 'dataRow' && e.dataField === 'Status') {
    //         console.log("Captured")
    //         if (e.row?.data.Status === 'Generated') {
    //            e.editorOptions.readOnly = true;
    //         } else {
    //            e.editorOptions.readOnly = false;
    //         }
    //     }
    // }

    return (
        <>
            <h1> { Title } </h1>
            <div className="block block__div--wrapper">
                <BaseDateField 
                    displayFormat={"monthAndYear"}
                    enableValidation={true}
                    inputAttr={{ "aria-label": "Billing Date" }}
                    isValid={billingDateValue ? true : false}
                    label={"Select a Bill Date"}
                    valueUpdatedByUser={handleDateValueChange}
                    value={billingDateValue}
                    variant={"outlined"}
                />
                <BaseSelectField 
                    id="billingStatusDropdown" 
                    data={billStatusData}
                    inputAttr={{ "aria-label": "Billing Status" }}
                    label={"Select a Bill Status"}
                    optionUpdatedByUser={onUpdatedBillStatus}
                    optionsLabel={"displayValue"}
                    requireValidation={false}
                    value={billStatusName}
                    valueExpr={"param"}
                />
                <BaseButton 
                    label={"Submit"}
                    onClick={getData}
                    variant={'outlined'}
                />
                <BaseButton 
                    disabled={!dataSource}
                    label={"View Status Histories"}
                    onClick={() => setEnableVisible(!enablePopup)}
                    variant={'outlined'}
                />
            </div>
            <div className="block block__div--wrapper">
                <BaseButton 
                    disabled={!dataSource}
                    label={"Approve All"}
                    onClick={setApproveAllStatus}
                    variant={'outlined'}
                />
                <BaseButton 
                    disabled={!dataSource}
                    label={"Reject All"}
                    onClick={setRejectAllStatus}
                    variant={'outlined'}
                />
            </div> 
            <Popup
                container=".dx-viewport"
                dragEnabled={false}
                hideOnOutsideClick={false}
                height={700}
                onHiding={() => setEnableVisible(false)}
                showCloseButton={true}
                showTitle={true}
                title="Status Histories"
                visible={enablePopup}
            >
                <Position
                    at="center"
                    collision="fit"
                    of={ window }
                    my="center"
                />
                <DataGrid 
                    dataSource={statusHistory}
                    columnAutoWidth={true}
                    keyExpr={"WorkflowItemId"}
                    keyboardNavigation={{
                        enabled: true,
                        enterKeyAction: 'none',
                        enterKeyDirection: 'none'
                    }}
                    onCellPrepared={CustomPreparedHeaderCell}
                    rowAlternationEnabled={true}
                    scrolling={{ mode: 'standard' }}
                    showColumnLines={true}
                    showRowLines={true}
                >
                    <Pager
                        allowedPageSizes={allowedPageSizes}
                        showPageSizeSelector={true}
                    />
                    <Paging defaultPageSize={10} />
                    <Column 
                        caption="Id"
                        dataField="id"
                        dataType="number"
                        visible={true}
                    />
                    <Column 
                        caption="WorkflowItemId"
                        dataField="WorkflowItemId"
                        dataType="number"
                        visible={true}
                    />
                    <Column 
                        caption="Approve?"
                        dataField="WorkflowStepStatusId"
                        dataType="number"
                    />
                    <Column 
                        caption="Workflow Entity"
                        dataField="WorkflowEntity"
                        dataType="string"
                    />
                    <Column 
                        caption="Created By"
                        dataField="CreatedBy"
                        dataType="string"
                    />
                    <Column 
                        caption="Created Date"
                        dataField="CreatedDate"
                        dataType="date"
                    />
                    <Column 
                        caption="Updated By"
                        dataField="UpdatedBy"
                        dataType="string"
                    />
                    <Column 
                        caption="Additional Notes"
                        dataField="AdditionalNotes"
                        dataType="string"
                    />
                </DataGrid>
            </Popup>
            {
                dataSource && !billingDataLoadingIndicator &&              
                <div className="block block__div--wrapper">
                    <DataGrid 
                        width={'100%'}
                        columnAutoWidth={true}
                        dataSource={dataSource}
                        // focusedRowEnabled={true}
                        // focusedRowKey={focusedRowKey}
                        keyExpr={"Id"}
                        keyboardNavigation={{
                            enabled: true,
                            enterKeyAction: 'none',
                            enterKeyDirection: 'none'
                        }}
                        onCellPrepared={CustomPreparedHeaderCell}
                        // onEditorPreparing={onEditorPreparing}
                        // onFocusedRowChanged={onFocusedRowChanged}
                        // onRowClick={handleRowClick}
                        onRowUpdating={onRowUpdating}
                        rowAlternationEnabled={true}
                        scrolling={{ mode: 'standard' }}
                        showColumnLines={true}
                        showRowLines={true}
                    >
                        <Editing
                            allowUpdating={true}
                            mode="popup"
                            >
                            <Toolbar>
                                <Item name="Add a Record" />
                            </Toolbar>
                            <DataGrid_Popup 
                                showTitle={true}
                                title="Change Billing Record Status(s)" 
                            />
                        </Editing>
                        <KeyboardNavigation enabled={true} />
                        <Paging defaultPageSize={10} />
                        <Pager
                            allowedPageSizes={allowedPageSizes}
                            showPageSizeSelector={true}
                        />
                        <SearchPanel 
                            highlightCaseSensitive={true} 
                            visible={true} 
                        />
                        <Column 
                            type="buttons"
                            visibleIndex={0}
                        />
                        <Column 
                            allowEditing={false}
                            caption="ID"
                            dataField="Id"
                            dataType='number'
                            visible={false}
                        >
                            <FormItem visible={false} />
                        </Column>
                        <Column 
                            caption="Bill Date"
                            dataField="BillDate"
                            dataType='date'
                            allowEditing={false}
                            // will be appended to this record by the backend, not to be edited by a user
                            // cellRender={matchDateStringFormat}
                            // editCellRender={matchDateStringFormatOnEdit}
                        />
                        <Column 
                            allowEditing={true}
                            caption="Status"
                            dataField="Status"
                            dataType="string"
                        >
                            <Lookup     
                                dataSource={[
                                    {
                                        DisplayValue: 'Add',
                                        Param: 'Generated'
                                    },
                                    {
                                        DisplayValue: 'Modify',
                                        Param: 'Modified'
                                    },
                                    {
                                        DisplayValue: 'Reject',
                                        Param: 'Rejected'
                                    },
                                    {
                                        DisplayValue: 'Second Approval Needed',
                                        Param: '2ndApprovalNeeded'
                                        /**
                                         * @todo Option needs to be read only
                                         * */ 
                                    }
                                ]}
                                displayExpr="DisplayValue"
                                valueExpr="Param"
                            />
                        </Column>
                        <Column 
                            allowEditing={true}
                            caption="Updated By"
                            dataField="UpdatedBy"
                        />
                        <Column
                            allowEditing={true} 
                            caption="Account Code"
                            dataField="account_code"
                            dataType='string'
                            editorOptions={{
                                maxLength: 10
                            }}
                        />
                        <Column
                            allowEditing={true} 
                            caption="Address 1"
                            dataField="street_address_1"
                            dataType="string"
                            editorOptions={{
                                maxLength: 200
                            }}
                        />
                        <Column
                            allowEditing={true}
                            caption="Address 2"
                            dataField="street_address_2"
                            dataType="string"
                            editorOptions={{
                                maxLength: 50
                            }}
                        />
                        <Column 
                            allowEditing={true}
                            caption="City"
                            dataField="city_name"
                            dataType='string'
                            editorOptions={{
                                maxLength: 50
                            }}
                        />
                        <Column 
                            allowEditing={true}
                            caption="State"
                            dataField="state"  
                            dataType="string"
                            editorOptions={{
                                maxLength: 2
                            }}
                        />
                        <Column 
                            allowEditing={true}
                            caption="Zip Code"
                            dataField="zip_code"
                            dataType="string"
                            editorOptions={{
                                maxLength: 10
                            }}
                        /> 
                        <Column 
                            allowEditing={true}
                            caption="Congress Person"
                            dataField="congressman_senator"
                            dataType='string'
                            editorOptions={{
                                maxLength: 200
                            }}
                        />
                        <Column 
                            allowEditing={true}
                            caption="Region"
                            dataField="region"
                            dataType='string'
                            editorOptions={{
                                maxLength: 2
                            }}
                        />
                        <Column 
                            allowEditing={true}
                            caption="Bureau Name"
                            dataField="bureau_name"
                            dataType='string'
                            editorOptions={{
                                maxLength: 55
                            }}
                        />
                        <Column 
                            allowEditing={true}
                            caption="Bureau Short Name"
                            dataField="bureau_short_name"
                            dataType='string'
                            editorOptions={{
                                maxLength: 55
                            }}
                        />
                        <Column 
                            allowEditing={true}
                            caption="Bureau Code"
                            dataField="actual_agency_bureau_code"
                            dataType='string'
                            editorOptions={{
                                maxLength: 10
                            }}
                            />
                        <Column 
                            allowEditing={true}
                            caption="Adjustments GSA ID"
                            dataField="adjustmentgsaid"
                            dataType='number'
                        />
                        <Column 
                            allowEditing={true}
                            caption="Amort. Capital Adj. Charges"
                            dataField="amort_capital_adj_charges"
                            dataType='number'
                        />
                        <Column 
                            allowEditing={true}
                            caption="Amort. Capital Adj. Charges YTD"
                            dataField="amort_capital_adj_charges_year_to_date"
                            dataType='string'
                        />
                        <Column 
                            allowEditing={true}
                            caption="Amort. Capital Adj. Remarks"
                            dataField="amort_capital_adj_remarks"
                            dataType='string'
                            editorOptions={{
                                maxLength: 500
                            }}
                        />
                        <Column 
                            allowEditing={true}
                            caption="Amort. Capital Charges"
                            dataField="amortized_capital_charges"
                            dataType='number' 
                        />
                        <Column 
                            allowEditing={true}
                            caption="Amort. Capital Charges YTD"
                            dataField="amortized_capital_charges_year_to_date"
                            dataType='string'  
                        />
                        <Column 
                            allowEditing={true}
                            caption="Basic Adj. Charges"
                            dataField="basic_adj_charges"
                            dataType='number'
                        />
                        <Column 
                            allowEditing={true}
                            caption="Basic Adj Charges YTD"
                            dataField="basic_adj_charges_year_to_date"
                            dataType='number'
                        />
                        <Column 
                            allowEditing={true}
                            caption="Basic Adj. Remarks"
                            dataField="basic_adj_remarks"
                            dataType='string'
                            editorOptions={{
                                maxLength: 500
                            }}
                        />
                        <Column 
                            allowEditing={true}
                            caption="Basic Charges YTD"
                            dataField="basic_charges_year_to_date"
                            dataType='string'
                        />
                        <Column 
                            allowEditing={true}
                            caption="Basic Rate"
                            dataField="basic_rte"
                            dataType='number'
                        />
                        <Column 
                            allowEditing={true}
                            caption="Basic Security Charges"
                            dataField="basic_security_charges"
                            dataType='number'
                        />
                        <Column 
                            allowEditing={true}
                            caption="Billed Agency Bureau Code"
                            dataField="billed_agency_bureau_code"
                            dataType='string'
                        />
                        <Column 
                            allowEditing={true}
                            caption="Billing Period"
                            dataField="billing_period"
                            dataType='string'
                            editorOptions={{
                                maxLength: 200
                            }}
                        />
                        <Column 
                            allowEditing={true}
                            caption="Building Adj. Charges"
                            dataField="bldg_adj_charges"
                            dataType='number'  
                        />
                        <Column 
                            allowEditing={true}
                            caption="Building Adj. Charges YTD"
                            dataField="bldg_adj_charges_year_to_date"
                            dataType='number'
                        />
                        <Column 
                            allowEditing={true}
                            caption="Building Adj. Remarks"
                            dataField="bldg_adj_remarks"
                            dataType='string'
                            editorOptions={{
                                maxLength: 500
                            }}
                        />
                        <Column 
                            allowEditing={true}
                            caption="Building Specific Rate"
                            dataField="bldg_specific_rate"
                            dataType='number'
                        />
                        <Column 
                            allowEditing={true}
                            caption="Building Name"
                            dataField="building_name"
                            dataType='string'
                        />
                        <Column 
                            allowEditing={true}
                            caption="Sequence Number"
                            dataField="building_sequence_number"
                            dataType='string'
                            editorOptions={{
                                maxLength: 6
                                /**
                                 * @todo: 
                                 * needs custom validation or a mask for
                                 * applying a custom format - either AA1111 or AAAA11
                                 */
                            }}
                        />
                        <Column 
                            allowEditing={true}
                            caption="Building Specific Charges"
                            dataField="building_specific_charges"
                            dataType='number'  
                        />
                        <Column 
                            allowEditing={true}
                            caption="Building Specific Charges YTD"
                            dataField="building_specific_charges_year_to_date"  
                            dataType='string'
                        />
                        <Column 
                            allowEditing={true}
                            caption="CBR Number"
                            dataField="cbr_number"
                            dataType='string'
                            editorOptions={{
                                maxLength: 7
                            }}
                        />
                        <Column
                            allowEditing={true} 
                            caption="Double Amort Capital Rent Rate"
                            dataField="doubleamort_capital_rent_rate"
                            dataType='number'
                        />
                        <Column 
                            allowEditing={true}
                            caption="OA Effective Date"
                            dataField="oa_effective_date"
                            dataType='datetime'
                                                        /**
                             * @todo
                             * This string needs a mask and custom validation to
                             * format it as a date (mm/dd/yyyy) 
                             */
                        />
                        <Column 
                            allowEditing={true}
                            caption="OA Expiration Date"
                            dataField="oa_expiration_date" 
                            dataType='datetime' 
                            /**
                             * @todo
                             * This string needs a mask and custom validation to
                             * format it as a date (mm/dd/yyyy) 
                             */
                        />
                        <Column 
                            allowEditing={true}
                            caption="OA Number"
                            dataField="oa_number"
                            dataType='string'  
                        />
                        <Column 
                            allowEditing={true}
                            caption="PSO Adj. Charges"
                            dataField="pso_adj_charges"
                            dataType='number'
                        />
                        <Column 
                            allowEditing={true}
                            caption="PSO Adj. Charges YTD"
                            dataField="pso_adj_charges_year_to_date"
                            dataType='string'
                        />
                        <Column 
                            allowEditing={true}
                            caption="PSO Adj. Remarks"
                            dataField="pso_adj_remarks"
                            dataType='string'
                            editorOptions={{
                                maxLength: 500
                            }}
                        />
                        <Column 
                            allowEditing={true}
                            caption="PSO Charges"
                            dataField="pso_charges"
                            dataType='number'
                        />
                        <Column 
                            allowEditing={true}
                            caption="PSO Charges YTD"
                            dataField="pso_charges_year_to_date"
                            dataType='string'
                        />
                        <Column 
                            allowEditing={true}
                            caption="PSO Rent Rate"
                            dataField="pso_rent_rate"
                            dataType='number' 
                        />
                        <Column
                            allowEditing={true}
                            caption="RU Factor"
                            dataField="ru_factor"
                            dataType='string'
                            editorOptions={{
                                maxLength: 200
                            }}
                        />
                        <Column 
                            allowEditing={true}
                            caption="Square Feet"
                            dataField="sqft"
                            dataType='number'  
                        />
                        <Column 
                            allowEditing={false}
                            caption="String Bill"
                            dataField="stringbill_number"
                            dataType="string"
                        />
                        <Column 
                            allowEditing={true}
                            caption="String Page"
                            dataField="stringpage_number"
                            dataType="string" 
                        />
                        <Column 
                            allowEditing={false}
                            caption="String Record"
                            dataField="stringrecord_number"
                            dataType="number"
                        />
                        <Column 
                            allowEditing={true}
                            caption="TCM Adj. Charges"
                            dataField="tcm_adj_charges"
                            dataType="number"
                        />
                        <Column 
                            allowEditing={true}
                            caption="TCM Adj. Charges YTD"
                            dataField="tcm_adj_charges_year_to_date"
                            dataType="string" 
                        />
                        <Column 
                            allowEditing={true}
                            caption="TCM Adj. Remarks"
                            dataField="tcm_adj_remarks"
                            dataType="string"
                            editorOptions={{
                                maxLength: 500
                            }}
                        />
                        <Column 
                            allowEditing={true}
                            caption="TCM Charges"
                            dataField="tcm_charges"
                            dataType="number"
                        />
                        <Column 
                            allowEditing={true}
                            caption="TCM Charges YTD"
                            dataField="tcm_charges_year_to_date"
                            dataType="string"
                        />
                        <Column 
                            allowEditing={true}
                            caption="TCM Rent Rate"
                            dataField="tcm_rent_rate"
                            dataType="number"  
                        />
                    </DataGrid>
                </div>
            }
            {
                billingDataLoadingIndicator && 
                <>
                    <LoadingIndicatorCircle 
                        message="Loading your billing records. This may take a moment..."
                    />
                </>
            }
            <div>
            {/**
             * @todo: Refactor this Toast into a base component and consolidate.
             */}
                <Toast 
                    closeOnClick={true}
                    focusStateEnabled={true}
                    hideOnOutsideClick={true}
                    hideOnParentScroll={false}
                    message={errorMessage}
                    onHiding={() => errorAlertVisible(false)}
                    type="error"
                    visible={errorAlertVisible}
                    wrapper={{
                        id: 'toast-error-message'
                    }}
                    // displayTime={10000}
                />
                <Toast 
                    closeOnClick={true}
                    focusStateEnabled={true}
                    hideOnOutsideClick={true}
                    hideOnParentScroll={false}
                    message={"Bill record status change was successfully saved."}
                    onHiding={() => successAlertVisible(false)}
                    type="success"
                    visible={successAlertVisible}
                    wrapper={{
                        id: 'toast-success-message'
                    }}
                    // displayTime={10000}
                />
            </div>
        </>
    );
};

export default CRMA_HistoryBillGeneration;