import DataGrid, {
    KeyboardNavigation,
    Pager,
    Paging,
    SearchPanel,
    Export,
    Column,
    FilterRow,
    MasterDetail
} from 'devextreme-react/data-grid';
import { TabPanel, Item } from 'devextreme-react/tab-panel';
import CustomStore from 'devextreme/data/custom_store';
import { pageFormats, allowedPageSizes } from '../../components/GlobalDataGridConfigurations';
import CustomPreparedHeaderCell from '../../components/CustomPreparedHeaderCell';
import InfoIconComponent, { InfoIconDevExtremeComponent } from '../../components/CustomInfoIcon';
import { FOUOheader, FOUOfooter } from '../../components/FOUO';
import { useAuth } from '../../hooks/useAuth';
import ExportGrid from '../../components/ExportGrid';
import dayjs from 'dayjs';
import { ContractPersonnel } from '../../components/Contracts/ContractPersonnel';

const Contracts = (({ data }) => {
    const { user } = useAuth();

    const dataSource = new CustomStore({
        key: 'AuditTrailNumber',
        load: async () => {
            const mappedData = data.map((element) => {

                return {
                    AuditTrailNumber: element.AuditTrailNumber ?? "N/A",
                    ChangeType: element.ChangeType ?? "N/A",
                    Number: element.InitialValue.Number ?? "",
                    AwardDate: element.InitialValue.AwardDate ?? "",
                    StartDate: element.InitialValue.StartDate ?? "",
                    EndDate: element.InitialValue.EndDate ?? "",
                    TaskOrders: element.InitialValue.TaskOrders ?? [],
                    ContractPersonnel: element.InitialValue.ContractPersonnel ?? [],
                    RepresentativeOfficer: element.InitialValue.RepresentativeOfficer ?? "",
                    DateCreated: element.ChangeType.toLowerCase() === "insert" ? element.user : "N/A",
                    CreatedBy: element.ChangeType.toLowerCase() === "insert" ? element.user : "N/A",
                    UpdatedBy: element.ChangeType.toLowerCase() === "update" ? element.user : "N/A"
                }
            });
            return mappedData;
        }
    });
    return (
        <div>
            <FOUOheader
                dateString={dayjs.utc().format('MM/DD/YYYY h:mma') + " " + "UTC"}
                heading={"Audit - Task Order Changes"}
                userEmail={user.email}
            />
            <DataGrid
                columnAutoWidth={true}
                dataSource={dataSource}
                //  onCellPrepared={CustomPreparedHeaderCell}
                showBorders={true}
                showColumnLines={true}
                showRowLines={true}
                rowAlternationEnabled={true}
            //  onExporting={e => ExportGrid(e, "Audit_Report")}
            >
                <Column dataField="AuditTrailNumber" caption="Audit Trail Number" visible={false} />
                <Column dataField="ChangeType" caption="Change Type" />
                <Column dataField="Number" caption="Contract Number" />
                <Column dataField="AwardType" caption="Award Type" />
                <Column dataField="AwardDate" caption="Contract Award Date" dataType="date" />
                <Column dataField="StartDate" caption="Contract Start Date" dataType="date" />
                <Column dataField="EndDate" caption="Contract End Date" dataType="date"/>
                <Column dataField="CreatedBy" caption="Created By" />
                <Column dataField="UpdatedBy" caption="Changed By" />
                <MasterDetail
                    enabled={true}
                    render={DetailSection}
                />

            </DataGrid>
            <br></br>

            <FOUOfooter />
        </div>
    )
})
const DetailSection = ({ data }) => {
    return (
        <div>
            <TabPanel>
                <Item
                    title="Contract Personnel">
                    <DataGrid
                        dataSource={data.ContractPersonnel}
                        showBorders={true}
                        columnAutoWidth={true}
                        key="Id">
                        <Column dataField="Id" caption="Id" visible={false} />
                        <Column dataField="PersonnelTypeId" caption="Personnel Type" />
                        <Column dataField="OfficerFirstName" caption="First Name" />
                        <Column dataField="OfficerLastName" caption="Last Name" />
                        <Column dataField="OfficerEmail" caption="Email" />
                        <Column dataField="OfficerPhone" caption="Phone" />
                    </DataGrid>
                </Item>
                <Item
                    title="Task Orders">
                    <DataGrid
                        dataSource={data.TaskOrders}
                        showBorders={true}
                        columnAutoWidth={true}
                        key="Number">
                        <Column dataField="Number" caption="Task Order Number" />
                        <Column dataField="AwardDate" caption="Award Date" dataType="date" />
                        <Column dataField="StartDate" caption="Start Date" dataType="date" />
                        <Column dataField="EndDate" caption="End Date" dataType="date" />
                        <Column dataField="RegionId" caption="Region Id" />
                        <Column dataField="VendorName" caption="Vendor Name" />
                        <Column dataField="LineNumber" caption="Line Number" />
                        <Column dataField='ProductCodeId' caption="Product Code Id" />
                        <Column dataField='ProductDescriptionId' caption='Product Desc.' />
                        <Column dataField='FinancialCodes.AccSequenceNumber' caption="Acc. Seq. Number" />
                        <Column dataField='FinancialCodes.AccObligatedAmount' caption="Acc. Oblig. Amount" />
                        <Column dataField='FinancialCodes.CageCode' caption="Cage Code" />
                        <Column dataField='FinancialCodes.FinancialCode' caption="Acc. Code" />
                        <Column dataField='FinancialCodes.TreasuryAccSymbol' caption="Treasury Acc. Symbol" />
                        <Column dataField='FinancialCodes.UniqueEntityNumber' caption="UEI (Unique Entity ID Number)" />
                    </DataGrid>
                </Item>
            </TabPanel>

        </div>
    )
}
export default Contracts;