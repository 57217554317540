import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import ScrollView from 'devextreme-react/scroll-view';
import GetFetch from '../hooks/GetFetch';
import PutPostPatchFetch from '../hooks/PutPostPatchFetch';
import { LoadingIndicatorCircle } from "../components/Loading";
import { LoadingPanel } from '../components/LoadingPanel';
import { LoadPanel } from 'devextreme-react/load-panel';
import { ROUTE_EDIT_A_CONTRACT_RECORD } from '../routes/Routes';
import { ContractForm } from '../components/Contracts/ContractForm';
import '../sass/styles.scss';
import { Item, Toolbar } from 'devextreme-react/toolbar';
import Button from 'devextreme-react/button';
import DropDownButton from 'devextreme-react/drop-down-button';

/** @typedef {import('../types/contract').Contract} Contract */


const ContractUpdate = () => {
    /*     const title = ROUTE_EDIT_ADJUSTMENTS.title;
        const navigate = useNavigate(); */
    const [searchParams] = useSearchParams();
    const [contractNumber, setContractNumber] = useState(searchParams.get("id") || 'DEF123456789');
    const [data, setData] = useState(null);
    const [error, setError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const CONTRACT_NUMBER = contractNumber;
    const contractEndpoint = `/v1/Contract/GetContractByNumber/${CONTRACT_NUMBER}`;

    useEffect(() => {
        loadData();
    }, [CONTRACT_NUMBER]);

    const loadData = async () => {
        setIsLoading(true);
        const data = await GetFetch(contractEndpoint);
        console.log(data)
        const { Success, Message, Errors } = data;
        setError(Success === false);
        if (Success) {
            setData(Message);
        }
        setIsLoading(false);
    };
    
    return (
        <div>
            <h1>{ROUTE_EDIT_A_CONTRACT_RECORD.title}</h1>
            {error && <p>Something went wrong.</p>}
            {isLoading && !data && <LoadingPanel location={{of: '#contract-form'}} visible={true}  message='Loading Contract Edit...' />}
            {data &&
                <ScrollView className='view-wrapper-scroll'>
                    <div className='view-wrapper view-wrapper-contact-details'>
                        <div className='panels'>
                            <ContractForm
                                id='contract-form'
                                data={data}
                                isLoading={isLoading}
                                isEditing={false}
                            />
                        </div>
                    </div>
                </ScrollView>
            }
        </div>
    )
}
export default ContractUpdate;