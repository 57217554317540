import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import ScrollView from 'devextreme-react/scroll-view';
import GetFetch from '../hooks/GetFetch';
import PutPostPatchFetch from '../hooks/PutPostPatchFetch';
import { LoadingIndicatorCircle } from "../components/Loading";
import { ROUTE_CREATE_A_NEW_CONTRACT_RECORD } from '../routes/Routes';
import { ContractForm } from '../components/Contracts/ContractForm';
import '../sass/styles.scss';
import { Item, Toolbar } from 'devextreme-react/toolbar';
import Button from 'devextreme-react/button';
import DropDownButton from 'devextreme-react/drop-down-button';

/** @typedef {import('../types/contract').Contract} Contract */


const ContractAdd = () => {
    const [searchParams] = useSearchParams();
    const [contractNumber, setContractNumber] = useState(searchParams.get("id") || 'DEF123456789');
    const [data, setData] = useState(null);
    const [error, setError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    // const CONTRACT_NUMBER = contractNumber;
    // const contractEndpoint = `/v1/Contract/GetContractByNumber/${CONTRACT_NUMBER}`;

/**
 * @name newContractData
 * @type {Contract}
 */
    const newContractData = {
        ContractId: 0,
        ContractNumber: null,
        AwardDate: null,
        StartDate: null,
        EndDate: null,
        ContractPersonnel: [],
        TaskOrders: []
    }

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        setIsLoading(true);
        setData(newContractData);
        console.log(data)
        setIsLoading(false);
    };
    
    return (
        <div>
            <h1>{ROUTE_CREATE_A_NEW_CONTRACT_RECORD.title}</h1>
            {error && <p>Something went wrong.</p>}
            {isLoading && !data && <LoadingIndicatorCircle message="Loading the Contract..." />}
            {data &&
                <ScrollView className='view-wrapper-scroll'>
                    <div className='view-wrapper view-wrapper-contact-details'>
                        <div className='panels'>
                            <ContractForm
                                data={data}
                                isLoading={isLoading}
                                isEditing={true}
                            />
                        </div>
                    </div>
                </ScrollView>
            }
        </div>
    )
}
export default ContractAdd;