
/**
 * 
 * @enum This file is a collection of stored content that can be shared globally in RMS.
 * 
 */

export const Content = {
    Alerts: {
        BuildingSavedSuccssful: "Building was successfully submitted.",
        UserSavedSuccessful: `User was save successfully. Click the "Forward" icon to be routed back to Customer Accounts Page.`,
        CustomerSavedSuccessful: `Customer was saved successfully. Click the "Forward" icon to be routed back to System Users Page.`,
        SecurityCostSavedSuccssful: `Security Cost was successfully submitted. Click the "Forward" icon to be routed back to System Users Page.`,
        AdjustmentSavedSuccssful: `Adjustment was successfully submitted. Click the "Forward" icon to be routed back to Adjustments Page.`,
        ContractSavedSuccessful: `Contract was saved successfully. Click the "Forward" icon to be routed back to Contracts Page.`,
    },
    Buttons: {
        cancel: "Cancel",
        editBuildingButtonLabel: "Edit a Building",
        editUserButtonLabel: "Edit a User",
        editCustomerButtonLabel: "Edit a Customer",
        newBuildingButtonLabel: "Add a Building",
        newUserButtonLabel: "Add a New User",
        newCustomerButtonLabel: "Add a new Customer",
        selectBuildingsLabel: "Select Buildings",
        submit: "Submit",
        MUI: {
            Severity: {
                success: "success",
                warning: "warning",
                info: "info"
            },
            Variant: {
                outlined: "outlined"
            }
        }
    },
    Inputs: {
        labelModes: {
            _floating: "floating",
            _hidden: "hidden",
            _static: "static"
        },
        Checkbox: {
            TieService: {
                checkedMessage: "Tie Override checkbox is checked",
                tie_text: "Tie Override",
                uncheckedMessage: "Tie Override checkbox is unchecked"
            }
        },
        Dates: {
            min: new Date(1950, 0, 1),
            max: new Date(2100, 0, 1),
            invalidStartDateMessage: "The date you have entered is out of the acceptable range. Please provide a Contract Start Date that is no earlier than 1/1/1950, no later than earlier 1/1/2100, and not after the Contract End Date.",
            invalidEndDateMessage: "The date you have entered is out of the acceptable range. Please provide a Contract End Date that is no earlier than 1/1/1950, no later than earlier 1/1/2100, and not after the Contract End Date.",
            invalidSeperationDateMessage: "The date you have entered is out of the acceptable range. Please provide a Seperation Date that is no earlier than 1/1/1950 and no later than earlier 1/1/2100 the End Date.",
        },
        EmployeeType: [
            {
                id: "Contractor",
                label: "Contractor",
            },
            {
                id: "Federal",
                label: "Federal"
            }
        ],
        EmployeeTypeRefs: {
            EmployeeTypeId: "id",
            EmployeeTypeLabel: "label"
        },
        Input_NumberBox_Label: {
            adjustedRentSquareFootageLabel: "Adjusted Rent Sq. Ft.",
            adjustedUsageSquareFootageLabel: "Adjusted Usage Sq. Ft",
            ansiRentSquareFootageLabel: "Ansi Rent Sq. Ft.",
            ansiUsableSquareFootageLabel: "Ansi Usable Sq. Ft.",
            communityJointUsageSquareFootage: "Community Joint Use Sq. Ft.",
            jointUseSquareFootageLabel: "Joint Use Sq. Ft.",
            leaseJointUseSquareFootageLabel: "Lease Joint Use Sq. Ft.",
            rentUsageFactorLabel: "Rent Usage Factor",
            pmoChargesLabel: "PSO Charges *",
            tcmChargesLabel: "TCM Charges *"
        },
        Input_TextField_Labels: {
            addressLabel: "Address *",
            addressOneLabel: "Address 1 *",
            addressTwoLabel: "Address 2",
            cityNameLabel: "City *",
            congressionalRepresentativeLabel: "Congressional Representative",
            buildingFacilityLabel: "Facility *",
            buildingNameLabel: "Name *",
            buildingNumberLabel: "Building Number *",
            buildingSequenceNumberLabel: "Building Sequence Number *",
            contractorCompanyLabel: "Contractor Company",
            contractStartDateLabel: "Contract Start Date *",
            contractEndDateLabel: "Contract End Date *",
            employeeTypeLabel: "Employee Type *",
            jobTitleLabel: "Job Title",
            regionsLabel: "Regions *",
            separationDateLabel: "Separation Date *",
            userAccountLabel: "User Account *",
            zipCodeLabel: "Zip Code *",
            customer_labels: {
                agencyNameLabel: "Agency Name *",
                abNameLabel: "Agency Bureau Name *",
                abShortNameLabel: "Agency Bureau Short Name",
                abCodeLabel: "Agency Bureau Code *",
                billedABCodeLabel: "Billed Agency Bureau Code *",
                actualABCodeLabel: "Actual Agency Bureau Code *",
                agencyLocationCodeLabel: "Agency Location Code *",
            }
        },
        Input_Validation_Messages: {
            abCodeValidationMessage: "Please ensure to use numbers only. Example code: 1234"
        },
        Input_SelectField_Labels: {
            userRoleLabel: "User Role *",
            statusLabel: "Status *",
            statusListOptionsLabel: "Name",
            singleRegionLabel: "Region *",
            rolesListOptionsLabel: "Name",
            usStateLabel: "State (Apprev.) *",
            buildingSequenceNumberLabel: "Building Number *",
            fiscalYearLabel: "Fiscal Year *"
        },
        Input_SelectField_Ids: {
            statusListId: "StatusId",
            rolesListId: "RoleId",
        },
        ValidationMessages: {
            noNull: "This field cannot be empty. Please provide a value."
        }
    },
    Masks: {
        zipCodeInvalidMessage: "Zip Code must be 5 or 9 digits in length.",
        zipCodeMask: "00000"
    },
    NummberFormatting: {
        decimalFormat: "###,###,###.##",
        currencyFormat: "$ #,##0"
    },
    Titles: {
        _AddBuilding: "Add a Building",
        _EditBuilding: "Edit a Building"
    }
};