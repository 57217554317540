
export const validationRules = {
    firstName: [{ type: 'required', message: 'First Name is required.' }],
    lastName: [{ type: 'required', message: 'Last Name is required.' }],
    phone: [{ type: 'required', message: 'Phone number is required.' }],
    email: [{ type: 'email', message: 'Email is incorrect.' }],
    startDate: [
        { 
            type: 'required', 
            message: 'Start Date is required.'
        },
    ],
    endDate: [
        { 
            type: 'required', 
            message: 'End Date is required.'
        },
        { 
            type: 'compare', 
            comparisonTarget() {
                const startDate = $('#StartDate').dxDateBox('instance');
                if (startDate) {
                  return startDate.option('value');
                }
                return null;
              },
            comparisonType: '==',
            message: 'End Date must be equal to or greater than Start Date.'
        },
    ],
};

export const notesEditorOptions = { height: 90, maxLength: 200 };
export const phoneEditorOptions = { 
    mask: '+1 (X00) 000-0000', 
    maskRules: { X: /[02-9]/ },
    maskInvalidMessage: 'The phone must have a correct USA phone format',
};
export const dateEditorOptions = { 
    displayFormat: 'shortdate',
    placeholder: 'MM/dd/yyyy',
    useMaskBehavior: true
};