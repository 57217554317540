import React from 'react';

import TextBox, { Button as TextBoxButton } from 'devextreme-react/text-box';
import Validator, { RequiredRule } from 'devextreme-react/validator';
import './FormTextBox.scss';

/* type TextBoxProps = {
  value: string,
  label?: string,
  isEditing: boolean,
  onValueChange: (data) => void,
  mask?: string,
  icon?: string,
} */

/**
 * @typedef {object} TextBoxProps
 * @property {string} value
 * @property {string?} label
 * @property {boolean} isEditing
 * @property {function(data): void} onValueChange
 * @property {string?} mask
 * @property {string?} icon
 */

/**
 * @param {TextBoxProps} data
 */

export const FormTextBox = ({ value, label, isEditing, onValueChange, mask = '', icon, children}) => {
  return (
    <TextBox
      label={label}
      value={value}
      mask={mask}
      readOnly={!isEditing}
      elementAttr={{ class: 'form-editor' }}
      inputAttr={{ class: 'form-editor-input' }}
      stylingMode='filled'
      onValueChange={onValueChange}
    >
      { icon &&
      <TextBoxButton
        name='icon'
        location='before'
        options={{
          icon: icon,
          stylingMode: 'text',
          elementAttr: { class: 'form-editor-icon' }
        }} />
      }
      <Validator>
        {/* <RequiredRule /> */}
        {children}
      </Validator>
    </TextBox>
  );
};